import dayjs from 'dayjs';

export const numberWithoutMask = (value: string): string => value.replace(/[^\d]/g, '');

export const formatCurrency = (value: number, currency: string): string => {
  const formatter = new Intl.NumberFormat([], {
    style: 'currency',
    currency
  });

  return formatter.format(value);
};

export const validNumberCard = (value: string) => {
  if (
    String(value)
      .split('')
      .every((result, _, original) => result === original[0]) ||
    value === '123456789000' ||
    value === '123456789123' ||
    value.length < 12
  ) {
    return false;
  } else return true;
};

export const validDateCard = (value: string, selectedDay: string) => {
  const date = value.split('/');
  const dateFormat = dayjs(`${date[2]}-${date[1]}-${date[0]}`).set('hour', 23).set('minute', 59).set('second', 59);
  if (
    value.length < 10 ||
    dateFormat.isBefore(dayjs(new Date())) ||
    dayjs(dateFormat).isBefore(dayjs(selectedDay)) ||
    !dateFormat.isValid() ||
    Number(date[1]) > 12 ||
    Number(date[0]) > 31
  ) {
    return false;
  } else return true;
};

export const dateFormatIso = (date: string[]) => {
  return dayjs(`${date[2]}-${date[1]}-${date[0]}`).toISOString();
};

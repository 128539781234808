import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Theme from './components/quarks/Theme';
import TagManager from 'react-gtm-module';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { setupStore } from './store';
import App from './layout';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const store = setupStore();
const persistor = persistStore(store);

if (process.env.REACT_APP_STAGE === 'production') {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '',
    auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
    preview: process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW
  });
}
root.render(
  <ThemeProvider theme={Theme}>
    <CssBaseline />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <App />
        </Router>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

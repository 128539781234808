import { lazy } from 'react';
import { ProductState } from '../features/product/product-slice';

export const lazyWithRetry = (componentImport: any, path: string) => {
  return lazy(async () => {
    try {
      return await componentImport();
    } catch (error) {
      const persist = sessionStorage.getItem('persist:root');
      const persistParsed = JSON.parse(persist || '{}');
      const persistParsedProduct = JSON.parse(persistParsed?.product || '{}') as ProductState;
      console.error(`Error tratado - ${path}`, error);
      switch (path) {
        case 'PersonalData':
          if (persistParsedProduct && persistParsedProduct.id) {
            if (persistParsedProduct?.calendar.userId) {
              return (window.location.href = `/servico/${persistParsedProduct?.id}/especialista/${persistParsedProduct?.calendar.userId}`);
            } else {
              return (window.location.href = `/servico/${persistParsedProduct?.id}`);
            }
          }
          return (window.location.href = '/selecao-de-servicos');
        default:
          return window.location.reload();
      }
    }
  });
};

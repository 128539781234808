import { BoxdWithSpacing } from '../../organisms/Grid';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ReactComponent as Prescriptions } from '../../../images/prescriptions.svg';
import { ReactComponent as Stethoscope } from '../../../images/stethoscope.svg';
import { ReactComponent as Calendartwo } from '../../../images/calendartwo.svg';
import { ReactComponent as DriveFolderUploadOutlinedIcon } from '../../../images/drive_folder_upload.svg';
import CardContent from '@mui/material/CardContent';
import { CardItemPayment, GridItemPayment } from './style';
import Button from '@mui/material/Button';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CircleIcon from '../../atoms/CircleIcon';

interface ValueCardsItensProps {
  icon: JSX.Element;
  title: string;
  description: string;
}

const valueCardsItens: ValueCardsItensProps[] = [
  {
    icon: <Stethoscope width={'32px'} />,
    title: 'Descubra quem vai te atender',
    description: 'Visualize quem é a médica especialista responsável pelo seu atendimento'
  },
  {
    icon: <DriveFolderUploadOutlinedIcon width={'32px'} />,
    title: 'Envie exames',
    description: 'Compartilhe os resultados dos seus exames com a sua equipe de cuidados'
  },
  {
    icon: <Prescriptions width={'32px'} />,
    title: 'Visualize resultados e receitas',
    description: 'Acesse os arquivos a qualquer momento e de qualquer dispositivo'
  },
  {
    icon: <Calendartwo width={'32px'} />,
    title: 'Remarque seu agendamento',
    description: 'Veja a agenda completa e, se necessário, faça alterações de maneira rápida e fácil'
  }
];

const AlreadyPayKnowPlataform = () => {
  return (
    <>
      <BoxdWithSpacing>
        <Grid container>
          <Grid item xs={12} md={4} maxHeight={{ xs: 'max-content', md: 0 }}>
            <Box sx={{ mb: 2 }}>
              <CircleIcon icon={<CheckOutlinedIcon />} />
            </Box>
            <Box>
              <Typography variant="h3" sx={{ mb: 2 }}>
                Este pagamento já foi realizado.
              </Typography>
              <Typography variant="body1">Para mais informações entre na sua conta da Oya Care.</Typography>
            </Box>
          </Grid>
          <Grid item xs={0} md={1}></Grid>
          <Grid container xs={12} md={7}>
            <Box sx={{ display: { xs: 'block', md: 'none' }, pt: 3, pb: 3, width: '100%' }}>
              <Button
                variant="contained"
                fullWidth
                href={process.env.REACT_APP_URL_MYACCOUNT || '#'}
                target="_self"
                sx={{ letterSpacing: 0 }}
              >
                Entrar na minha conta
              </Button>
            </Box>

            {valueCardsItens.map((resp: ValueCardsItensProps, index: number) => {
              return (
                <GridItemPayment key={index} item xs={12} md={12} lg={6} className={`item-${index}`}>
                  <CardItemPayment sx={{ minWidth: 302, maxHeight: 120 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', minHeight: '90px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }} mr={{ xs: 2 }}>
                          {resp.icon}
                        </Box>
                        <Box>
                          <Typography variant="body1B">{resp.title}</Typography>
                          <Typography mt={1} variant="body1">
                            {resp.description}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </CardItemPayment>
                </GridItemPayment>
              );
            })}

            <Box sx={{ display: { xs: 'none', md: 'block' }, pt: 3 }}>
              <Button
                variant="contained"
                fullWidth
                href={process.env.REACT_APP_URL_MYACCOUNT || '#'}
                target="_self"
                sx={{ letterSpacing: 0 }}
              >
                Entrar na minha conta
              </Button>
            </Box>
          </Grid>
        </Grid>
      </BoxdWithSpacing>
    </>
  );
};
export default AlreadyPayKnowPlataform;

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Questions = [
  {
    id: 'o-que-e-o-amh',
    summary: 'O que é o exame AMH?',
    details: (
      <Typography variant="body1">
        O exame antimulleriano, também chamado de AMH ou HAM, tem a função de analisar de forma quantitativa a reserva
        ovariana a partir de uma coleta de sangue.
      </Typography>
    )
  },
  {
    id: 'por-que-este-exame-e-importante',
    summary: 'Por que este exame é importante?',
    details: (
      <Typography variant="body1">
        É com base no exame AMH e nas demais informações sobre você que nossa equipe vai te ajudar a planejar sua vida
        fértil. Dependendo do resultado (e da sua vontade!) também te orientamos a respeito de alternativas como congelamento
        de óvulos, FIV, inseminação artificial, etc.
      </Typography>
    )
  },
  {
    id: 'como-funciona-para-agendar',
    summary: 'Como funciona para agendar?',
    details: (
      <Typography variant="body1">
        Se você estiver numa região com disponibilidade de atendimento da Oya, basta escolher dia e horário aqui no nosso
        site, preencher dados pessoais, endereço, realizar o pagamento e nós vamos até você! Se estiver fora da área de
        atendimento da Oya, a gente te manda o pedido médico assim que você agendar a consulta com especialista em
        fertilidade. É só mandar uma mensagem no{' '}
        <Link href="https://bit.ly/Fale_com_a_Oya" target="_blank">
          WhatsApp
        </Link>
        ! Ps: Grande parte dos laboratórios externos não exige um pedido médico para a realização do exame AMH.
      </Typography>
    )
  },
  {
    id: 'onde-fazer-esse-exame',
    summary: 'Onde fazer esse exame?',
    details: (
      <Typography variant="body1">
        Dependendo da região, é possível fazer o exame AMH com a Oya - direto na sua casa de ou onde você quiser! Se essa não
        for uma opção pra você, é possível realizar o AMH em qualquer laboratório - esse é um exame de sangue realizado pela
        maior parte dos laboratórios, principalmente os de grande porte. Você não deve ter dificuldade para encontrá-lo.
      </Typography>
    )
  },
  {
    id: 'quanto-tempo-demora-para-o-exame-ficar-pronto',
    summary: 'Quanto tempo demora para o exame ficar pronto?',
    details: (
      <Typography variant="body1">
        Com a Oya, o exame costuma levar até 5 dias úteis para ficar pronto. Em outros laboratórios, o prazo médio costuma
        ser de 8 dias. Não esqueça de levar isso em conta na hora de agendar sua consulta online! Se o exame AMH não ficar
        pronto a tempo, fica tranquila: você pode reagendar sua consulta online com especialista em fertilidade com até 3
        horas de antecedência sem nenhum custo adicional.
      </Typography>
    )
  },
  {
    id: 'este-exame-e-coberto-pelo-convenio',
    summary: 'Este exame é coberto pelo convênio?',
    details: (
      <Typography variant="body1">
        O exame AMH não faz parte da cobertura da maioria dos planos de saúde. Recomendamos que você entre em contato com seu
        convênio para confirmar a disponibilidade.
      </Typography>
    )
  },
  {
    id: 'tem-um-dia-certo-para-fazer-o-exame-amh',
    summary: 'Tem um dia certo para fazer o exame AMH?',
    details: (
      <Typography variant="body1">
        Não! O HAM pode ser avaliado em qualquer período do mês - inclusive se você estiver menstruada.
      </Typography>
    )
  }
];

export default Questions;

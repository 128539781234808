import { createSlice } from '@reduxjs/toolkit';
import { IAppointments } from '../../components/molecules/Schedules/hooks';

export interface GroupTimes {
  [key: string]: IAppointments;
}

interface IScheduleState {
  dateStart: string;
  selectedDay: string;
  firstDaysAvailable: string[];
  selectedDayByDatePicker: boolean;
  events: string;
}

const initialState: IScheduleState = {
  dateStart: '',
  selectedDay: '',
  firstDaysAvailable: [],
  selectedDayByDatePicker: false,
  events: ''
};

// com o toolkit essa função substitui o reducer
export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    saveSelectedDate: (state, action) => {
      state.dateStart = action.payload;
    },
    saveSelectedDay: (state, action) => {
      state.selectedDay = action.payload;
    },
    saveSelectedDayByDatePicker: (state, action) => {
      state.selectedDayByDatePicker = action.payload;
    },
    saveFirstDaysAvailable: (state, action) => {
      state.firstDaysAvailable = action.payload;
    },
    updateScheduleEvents: (state, action) => {
      state.events = action.payload;
    },
    resetSchedule: state => {
      state.dateStart = '';
      state.selectedDay = '';
      state.firstDaysAvailable = [];
      state.selectedDayByDatePicker = false;
      state.events = '';
    }
  }
});

export const {
  saveSelectedDate,
  saveSelectedDay,
  saveSelectedDayByDatePicker,
  saveFirstDaysAvailable,
  updateScheduleEvents,
  resetSchedule
} = scheduleSlice.actions;
export const scheduleReducer = scheduleSlice.reducer;

import Accordion from '../../Accordion';
import data from './data';
import KnowMoreInsideModal from '../../KnowMoreInsideModal';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BasicModalTwo from '../../ModalTwo';

interface ModalExameAMHProps {
  openCloseController: boolean;
  onCloseHandler: () => void;
}

const ModalExameAMH = ({ openCloseController, onCloseHandler }: ModalExameAMHProps) => {
  return (
    <>
      <BasicModalTwo
        open={openCloseController}
        onCloseHandler={onCloseHandler}
        title="O exame AMH"
        extraContent={
          <KnowMoreInsideModal
            title="Ainda tem dúvidas?"
            subtitle="Aqui tem mais jeitos de explorar como podemos te ajudar a descobrir sobre a sua fertilidade"
            primaryButton={{
              label: 'Falar com a gente',
              href: `${process.env.NEXT_PUBLIC_WHATSAPP}`,
              icon: <WhatsAppIcon />,
              target: '_blank'
            }}
            secundaryButton={{
              label: 'Ver dúvidas frequentes',
              href: '/sobre/perguntas-frequentes',
              icon: <ArrowForwardIcon />
            }}
          />
        }
      >
        <Accordion questions={data} />
      </BasicModalTwo>
    </>
  );
};

export default ModalExameAMH;

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FC, useEffect } from 'react';
import CardDoctor from '../../components/molecules/CardDoctor';
import ShoppingCart from '../../components/molecules/ShoppingCart';
import { ViewContent, ViewContentCenter, ViewRoot, ViewSidebar } from '../../layout/Main/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { enabledBackButtonOnSchedulePage, saveCurrentStep } from '../../features/stepper/stepper-slice';
import { useDispatch } from 'react-redux';
import Stepper from '../../components/molecules/Stepper';

export const ChooseDoctor: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(saveCurrentStep('chooseDoctor'));
    dispatch(enabledBackButtonOnSchedulePage(true));
  }, []);

  return (
    <>
      <ViewRoot>
        <ViewContent className="ViewContent">
          <ViewContentCenter>
            <Box mb={3}>
              <Stepper separator="de" text="Escolha agendamento" />
            </Box>
            <Typography variant="h3" sx={{ mb: 4 }}>
              Escolha uma médica
            </Typography>
            <Box mb={1} sx={{ maxWidth: '800px' }}>
              <CardDoctor />
            </Box>

            <Box mb={5} mt={3}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ padding: '12px 24px 14px 16px' }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
                <Typography variant="body1" sx={{ ml: 1.5 }}>
                  Voltar
                </Typography>
              </Button>
            </Box>
          </ViewContentCenter>
        </ViewContent>
        <ViewSidebar component="aside" className="ViewSidebar">
          <ShoppingCart />
        </ViewSidebar>
      </ViewRoot>
    </>
  );
};

export default ChooseDoctor;

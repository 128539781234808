import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ViewRoot, ViewContentTwo } from '../../layout/Main/styled';
import { saveCurrentStep } from '../../features/stepper/stepper-slice';
import ContentPaymentAlreadyDone from '../../components/molecules/ContentPaymentAlreadyDone';

const PaymentAlreadyDone = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(saveCurrentStep('paymentAlreadyDone'));
  }, []);
  return (
    <>
      <ViewRoot>
        <ViewContentTwo className="ViewContent">
          <ContentPaymentAlreadyDone />
        </ViewContentTwo>
      </ViewRoot>
    </>
  );
};

export default PaymentAlreadyDone;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface RedirectPaymentState {
  url: string;
}

const initialState: RedirectPaymentState = {
  url: ''
};

export const urlSlice = createSlice({
  name: 'RedirectPayment',
  initialState,
  reducers: {
    saveRedirectPayment: (state, action: PayloadAction<RedirectPaymentState>) => {
      return (state = { ...initialState, ...action.payload });
    }
  }
});

export const { saveRedirectPayment } = urlSlice.actions;
export const redirectPaymentReducer = urlSlice.reducer;

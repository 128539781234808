import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.neutrals.beige1,
    borderRadius: '15px',
    [theme.breakpoints.down('md')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    },
    overflowX: 'hidden'
  },
  '& .MuiDialogContent-root': {
    margin: '20px 12px 40px 24px',
    padding: '0px',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '89%'
    }
  },
  '& .MuiDialog-scrollPaper': {
    margin: '80px, 32px, 32px, 32px'
  },
  '& .MuiDialogContentText-root': {
    color: theme.palette.neutrals.lead
  }
}));

export const DialogTitleWrapper = styled(DialogTitle)(({ theme }) => () => ({
  backgroundColor: theme.palette.neutrals.beige1,
  padding: '24px 16px 24px 16px'
}));

export const DialogContentWrapper = styled(DialogContent)(({ theme }) => () => ({
  backgroundColor: theme.palette.neutrals.beige1,
  boxShadow: '5px',
  paddingBottom: '10px',
  outline: 'none',
  overflowX: 'hidden',
  marginBottom: '40px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    transform: 'none',
    top: 'initial',
    bottom: 0,
    left: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  '&::-webkit-scrollbar': {
    width: '0.4em'
  },
  '&::-webkit-scrollbar-track': {
    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.neutrals.beige4,
    borderRadius: '16px'
  }
}));

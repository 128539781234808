import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { customEventTracking, eventTracking } from '../../lib/gtm';
import { useNavigate, useParams } from 'react-router-dom';
import { IProductResult, useGetProductDetailsMutation } from '../../services/product.service';
import {
  ServiceAvailableCity,
  isServiceAvailableCityFromZipCode,
  saveAvailableCity,
  saveProductDetail,
  saveCalendarDetails
} from '../../features/product/product-slice';
import { enabledBackButtonOnSchedulePage } from '../../features/stepper/stepper-slice';
import Button from '@mui/material/Button';
import { ViewContent, ViewContentCenter, ViewRoot, ViewSidebar } from '../../layout/Main/styled';
import { orderServiceSteps } from '../../utils';
import { saveTotalToPay } from '../../features/payment/payment-slice';

const ServiceAvailability = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productId } = useParams();
  const [getProductDetails, { data: dataProduct }] = useGetProductDetailsMutation();
  const getAvailableCity = (dataProduct: IProductResult): ServiceAvailableCity[] =>
    dataProduct.calendars.map(calendar => {
      return {
        city: calendar.city,
        calendarId: calendar.id
      };
    });

  const selectCalendarByCity = (city: string) => {
    if (!dataProduct) return;
    const calendar = dataProduct.calendars.find((calendar: any) => calendar.city === city);
    dispatch(
      saveCalendarDetails({
        id: calendar?.id,
        calendarType: calendar?.calendarType,
        durationEvent: calendar?.durationEvent
      })
    );
    customEventTracking.send({
      event: 'df_select_city',
      props: {
        city: city
      }
    });
    if (city === 'São Paulo') {
      navigate(`/servico/${process.env.REACT_APP_SCHEDULE_DF_SP}`);
    }
    if (city === 'Rio de Janeiro') {
      navigate(`/servico/${process.env.REACT_APP_SCHEDULE_DF_RJ}`);
    }
    if (city === 'Brasília') {
      navigate(`/servico/${process.env.REACT_APP_SCHEDULE_DF_BSB}`);
    }
  };
  useEffect(() => {
    if (productId) getProductDetails({ id: productId });
    dispatch(isServiceAvailableCityFromZipCode(true));
  }, []);

  useEffect(() => {
    if (dataProduct && productId) {
      dispatch(
        saveProductDetail({
          id: productId,
          services: dataProduct.services,
          servicesSteps: orderServiceSteps(dataProduct.servicesSteps),
          price: dataProduct.price,
          isNeedAddress: dataProduct.isNeedAddress,
          installment: dataProduct.maxInstallment,
          urlAnamnese: dataProduct.urlAnamnese,
          sendAnamnese: dataProduct.sendAnamnese,
          name: dataProduct.name
        })
      );
      dispatch(saveTotalToPay(dataProduct.price));
    }
  }, [dataProduct, productId]);

  useEffect(() => {
    if (dataProduct) {
      dispatch(saveAvailableCity(getAvailableCity(dataProduct)));
      dispatch(enabledBackButtonOnSchedulePage(true));
    }
  }, [dataProduct]);

  return (
    <>
      <ViewRoot>
        <ViewContent className="ViewContent">
          <ViewContentCenter>
            <Typography variant="h2" sx={{ mb: 1 }}>
              O primeiro passo para a Descoberta da Fertilidade é o exame AMH
            </Typography>
            <Typography variant="body1R" sx={{ mb: 3 }}>
              Confira a disponibilidade na sua região.
            </Typography>
            <Box>
              <Chip
                data-testid="cep-sp"
                label="São Paulo capital"
                variant="large"
                clickable
                onClick={() => selectCalendarByCity('São Paulo')}
                sx={{
                  display: 'block',
                  textAlign: 'left',
                  mb: 1
                }}
              />
              <Chip
                data-testid="cep-sp"
                label="Rio de Janeiro capital"
                variant="large"
                clickable
                onClick={() => selectCalendarByCity('Rio de Janeiro')}
                sx={{
                  display: 'block',
                  textAlign: 'left',
                  mb: 1
                }}
              />
              <Chip
                data-testid="cep-bsb"
                label="Brasília"
                variant="large"
                clickable
                onClick={() => selectCalendarByCity('Brasília')}
                sx={{
                  display: 'block',
                  textAlign: 'left',
                  mb: 1
                }}
              />
              <Chip
                data-testid="cep-others"
                label="Outra localização"
                variant="large"
                onClick={() => {
                  navigate(`cep`);
                  eventTracking.click({
                    category: 'CEP',
                    label: 'Outra localização'
                  });
                }}
                sx={{
                  display: 'block',
                  textAlign: 'left'
                }}
              />

              <Box>
                <Button
                  variant="inline"
                  color="primary"
                  sx={{
                    padding: 0,
                    mt: 3
                  }}
                  onClick={() => navigate(`/servico/${process.env.REACT_APP_SCHEDULE_DF_WITHOUT_EXAM}`)}
                >
                  Realizei o exame AMH nos últimos 6 meses
                </Button>
              </Box>

              <Box sx={{ mb: 7 }}>
                <Button variant="contained" color="secondary" sx={{ mt: 3 }} onClick={() => navigate(-1)}>
                  <ArrowBackIcon />
                  <Typography variant="body1" sx={{ ml: 1.5 }}>
                    Voltar
                  </Typography>
                </Button>
              </Box>
            </Box>
          </ViewContentCenter>
        </ViewContent>
        <ViewSidebar withimage="true" component="aside" className="ViewSidebar">
          <Typography color="neutrals.beige1" variant="h1">
            O que você precisa hoje?
          </Typography>
        </ViewSidebar>
      </ViewRoot>
    </>
  );
};

export default ServiceAvailability;

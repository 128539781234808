import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import { WrapperBox, MenuListItem, WrapperSubMenu } from './styled';
import { eventTracking } from '../../../../lib/gtm';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

type SubMenu = {
  [key: string]: boolean;
};

const MenuMobile = () => {
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState<SubMenu>({
    services: false,
    about: false,
    content: false
  });

  const handleDrawerClose = () => {
    eventTracking.click({
      category: 'Menu Mobile',
      label: 'Close Menu'
    });
    setOpen(false);
  };

  const handleToggleSubMenu = (active: string) => {
    setOpenSubMenu({
      ...openSubMenu,
      [active]: !openSubMenu[active]
    });
  };

  return (
    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
      {/* <MenuButton sx={{ p: 1 }} variant="text" onClick={handleDrawerOpen} endIcon={<DragHandleIcon />}>
        Menu
      </MenuButton> */}
      <Drawer
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        PaperProps={{ sx: { backgroundColor: 'neutrals.beige1', width: '80%' } }}
      >
        <WrapperBox role="presentation">
          <Box sx={{ textAlign: 'right', py: '16px', px: '24px' }}>
            <CloseIcon onClick={handleDrawerClose} />
          </Box>
          <List>
            <ListItem disablePadding>
              <MenuListItem>
                <Link to="https://www.oya.care/">
                  <Typography variant="body2R">Página inicial</Typography>
                </Link>
              </MenuListItem>
            </ListItem>

            {/* Sobre */}
            <ListItem disablePadding>
              <MenuListItem onClick={() => handleToggleSubMenu('about')}>
                <Typography variant="body2R" sx={{ mr: 1 }}>
                  Sobre
                </Typography>
                {openSubMenu.about ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </MenuListItem>
            </ListItem>
            <WrapperSubMenu in={openSubMenu.about} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <MenuListItem>
                  <Link to="https://www.oya.care/sobre/nossa-historia">
                    <Typography variant="body2R">Nossa história</Typography>
                  </Link>
                </MenuListItem>
              </List>
              <List component="div" disablePadding>
                <MenuListItem>
                  <Link to="https://www.oya.care/nossos-medicos">
                    <Typography variant="body2R">Nossos médicos</Typography>
                  </Link>
                </MenuListItem>
              </List>
              <List component="div" disablePadding>
                <MenuListItem>
                  <Link to="https://www.oya.care/sobre/perguntas-frequentes">
                    <Typography variant="body2R">Perguntas frequentes</Typography>
                  </Link>
                </MenuListItem>
              </List>
            </WrapperSubMenu>

            {/* Serviços */}
            <ListItem disablePadding>
              <MenuListItem onClick={() => handleToggleSubMenu('services')}>
                <Typography variant="body2R" sx={{ mr: 1 }}>
                  Serviços
                </Typography>
                {openSubMenu.services ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </MenuListItem>
            </ListItem>
            <WrapperSubMenu in={openSubMenu.services} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <MenuListItem>
                  <Link to="/">
                    <Typography variant="body2R">SOS Ginecologista Oya</Typography>
                  </Link>
                </MenuListItem>
              </List>
              <List component="div" disablePadding>
                <MenuListItem>
                  <Link to="https://www.oya.care/servicos/jornada-da-contracepcao">
                    <Typography variant="body2R">Jornada da Contracepção</Typography>
                  </Link>
                </MenuListItem>
              </List>
              <List component="div" disablePadding>
                <MenuListItem>
                  <Link to="https://www.oya.care/servicos/descoberta-da-fertilidade">
                    <Typography variant="body2R">Descoberta da Fertilidade</Typography>
                  </Link>
                </MenuListItem>
              </List>
            </WrapperSubMenu>

            {/* É profissional da saúde? */}
            <ListItem disablePadding>
              <MenuListItem>
                <Link to="https://www.oya.care/profissionais-da-saude">
                  <Typography variant="body2R">É profissional da saúde?</Typography>
                </Link>
              </MenuListItem>
            </ListItem>

            {/* Conteúdo */}
            <ListItem disablePadding>
              <MenuListItem onClick={() => handleToggleSubMenu('content')}>
                <Typography variant="body2R" sx={{ mr: 1 }}>
                  Conteúdo
                </Typography>
                {openSubMenu.content ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </MenuListItem>
            </ListItem>
            <WrapperSubMenu in={openSubMenu.content} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <MenuListItem>
                  <Link to="https://blog.oya.care/">
                    <Typography variant="body2R">Blog</Typography>
                  </Link>
                </MenuListItem>
              </List>
              <List component="div" disablePadding>
                <MenuListItem>
                  <Link to="https://www.oya.care/congelamento-de-ovulos">
                    <Typography variant="body2R">Ebooks</Typography>
                  </Link>
                </MenuListItem>
              </List>
              <List component="div" disablePadding>
                <MenuListItem>
                  <Link to="https://open.spotify.com/show/1HWeIrAXw2vdWUvmjqiLkn?si=nEPUlVzhQ7yUdf7WOOdC-A&nd=1">
                    <Typography variant="body2R">Podcast</Typography>
                  </Link>
                </MenuListItem>
              </List>
              <List component="div" disablePadding>
                <MenuListItem>
                  <Link to="https://open.spotify.com/user/0t6ihjux6ighb42xl6km0ln6l?si=cYf4FhpvQ4-TvmLTurUXVw&nd=1">
                    <Typography variant="body2R">Playlist</Typography>
                  </Link>
                </MenuListItem>
              </List>
              <List component="div" disablePadding>
                <MenuListItem>
                  <Link to="https://www.youtube.com/channel/UCzRsqNX2jv1voZe_ZxiDR2A">
                    <Typography variant="body2R">Videos</Typography>
                  </Link>
                </MenuListItem>
              </List>
            </WrapperSubMenu>

            <ListItem disablePadding>
              <MenuListItem>
                <Button
                  variant="contained"
                  fullWidth
                  endIcon={<WhatsAppIcon />}
                  href="https://bit.ly/Fale_com_a_Oya"
                  target="_blank"
                >
                  Entrar em contato
                </Button>
              </MenuListItem>
            </ListItem>
          </List>
        </WrapperBox>
      </Drawer>
    </Box>
  );
};

export default MenuMobile;

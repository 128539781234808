import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PersonalDataAddressState {
  cep: string;
  address: string;
  addressNumber: number | string;
  addressComplement?: string;
  addressReference?: string;
  neighborhood: string;
  city: string;
  state: string;
}

const initialState: PersonalDataAddressState = {
  cep: '',
  address: '',
  addressNumber: '',
  addressComplement: '',
  addressReference: '',
  neighborhood: '',
  city: '',
  state: ''
};

export const personalDataAddressSlice = createSlice({
  name: 'personalDataAddressState',
  initialState,
  reducers: {
    savePersonalAddressData: (state, action: PayloadAction<PersonalDataAddressState>) => {
      return (state = { ...initialState, ...action.payload });
    }
  }
});

export const { savePersonalAddressData } = personalDataAddressSlice.actions;
export const personalDataAddressReducer = personalDataAddressSlice.reducer;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Circle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80px',
  height: '80px',
  borderRadius: '100%',
  backgroundColor: theme.palette.neutrals.beige3
}));

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FC, useEffect } from 'react';
import ShoppingCart from '../../components/molecules/ShoppingCart';
import { ViewContent, ViewContentCenter, ViewRoot, ViewSidebar } from '../../layout/Main/styled';
import { ButtonCustom } from './styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProductDetailsMutation } from '../../services/product.service';
import { enabledBackButtonOnSchedulePage, saveCurrentStep } from '../../features/stepper/stepper-slice';
import { useDispatch } from 'react-redux';
import { saveCalendarDetails, saveProductDetail } from '../../features/product/product-slice';
import { saveTotalToPay } from '../../features/payment/payment-slice';
import { orderServiceSteps } from '../../utils';
import { customEventTracking } from '../../lib/gtm';
import Stepper from '../../components/molecules/Stepper';

export const ChooseAppointmentType: FC = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getProductDetails, { data }] = useGetProductDetailsMutation();

  useEffect(() => {
    if (productId) getProductDetails({ id: productId });
    dispatch(saveCurrentStep('chooseAppointmentType'));
    dispatch(enabledBackButtonOnSchedulePage(true));
  }, []);

  useEffect(() => {
    if (data && productId) {
      dispatch(
        saveProductDetail({
          id: productId,
          services: data.services,
          servicesSteps: orderServiceSteps(data.servicesSteps),
          price: data.price,
          isNeedAddress: data.isNeedAddress,
          installment: data.maxInstallment,
          urlAnamnese: data.urlAnamnese,
          sendAnamnese: data.sendAnamnese,
          name: data.name
        })
      );
      dispatch(saveTotalToPay(data.price));
      dispatch(
        saveCalendarDetails({
          id: data.calendars[0].id,
          calendarType: data.calendars[0].calendarType,
          durationEvent: data.calendars[0].durationEvent
        })
      );
    }
  }, [data, productId]);

  return (
    <>
      <ViewRoot>
        <ViewContent className="ViewContent">
          <ViewContentCenter>
            <Box sx={{ mb: 4 }}>
              <Stepper separator="de" text="Escolha agendamento" />
            </Box>
            <Typography variant="h3" sx={{ mb: 4 }}>
              Como você gostaria de agendar sua consulta?
            </Typography>
            <Box mb={1}>
              <ButtonCustom
                onClick={() => {
                  customEventTracking.send({ event: 'ra_available_times' });
                  navigate(`/servico/${process.env.REACT_APP_PRODUCT_RA}`);
                }}
              >
                <Typography variant="body1R">Ver os próximos horários disponíveis</Typography>
              </ButtonCustom>
            </Box>
            <Box mb={3}>
              <ButtonCustom
                onClick={() => {
                  customEventTracking.send({ event: 'ra_available_doctors' });
                  navigate('/servico/escolha-medica');
                }}
              >
                <Typography variant="body1R">Escolher médica que vai me atender</Typography>
              </ButtonCustom>
            </Box>
            <Box mb={35}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ padding: '12px 24px 14px 16px' }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
                <Typography variant="body1" sx={{ ml: 1.5 }}>
                  Voltar
                </Typography>
              </Button>
            </Box>
          </ViewContentCenter>
        </ViewContent>
        <ViewSidebar component="aside" className="ViewSidebar">
          <ShoppingCart />
        </ViewSidebar>
      </ViewRoot>
    </>
  );
};

export default ChooseAppointmentType;

import { useDispatch } from 'react-redux';
import { ViewRoot, ViewContentTwo } from '../../layout/Main/styled';
import { useEffect } from 'react';
import { saveCurrentStep } from '../../features/stepper/stepper-slice';
import ContentPaymentConfirmation from '../../components/molecules/ContentPaymentConfirmation';

const PaymentConfirmation = () => {
  const dispatch = useDispatch();

  const isLoading = false;
  useEffect(() => {
    dispatch(saveCurrentStep('paymentConfirmation'));
  }, []);
  return (
    <>
      <ViewRoot>
        <ViewContentTwo className="ViewContent">
          {isLoading ? <p>Carregando...</p> : <ContentPaymentConfirmation />}
        </ViewContentTwo>
      </ViewRoot>
    </>
  );
};

export default PaymentConfirmation;

import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';

export const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)(() => ({
  backgroundColor: 'transparent',
  ':before': {
    backgroundColor: 'transparent'
  }
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: 'transparent',
    padding: 0,
    borderBottom: `solid 1px ${theme.palette.neutrals.beige3}`,
    '.MuiTypography-root': {
      paddingRight: 24
    },
    '&.Mui-expanded': {
      borderColor: 'transparent'
    },
    '.MuiAccordionSummary-content': {
      padding: '12px 0px 12px 0'
    }
  })
);

export const WrapperIcon = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.brand.lightGreen,
  borderRadius: '100%',
  '& svg': {
    width: 16,
    height: 16
  }
}));

export const Details = styled(AccordionDetails)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: 40,
  paddingTop: 0,
  borderBottom: `solid 1px ${theme.palette.neutrals.beige3}`
}));

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Orquidea from '../../images/orquidea_03.png';

type ViewContentCenterType = {
  nopadding?: string;
};

type ViewSidebarProps = {
  withimage?: string;
};

export const ViewRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse'
  }
}));

export const ViewContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.beige1,
  minHeight: 'calc(100vh - 142px)',
  padding: 0,
  flexGrow: 1,
  paddingLeft: '80px',
  [theme.breakpoints.down('md')]: {
    minHeight: 'auto',
    padding: '0',
    width: '100%'
  }
}));

export const ViewContentTwo = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.beige1,
  minHeight: 'calc(100vh - 142px)',
  padding: 0,
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    minHeight: 'calc(100vh - 283px)',
    padding: '0',
    width: '100%'
  }
}));

export const ViewContentCenter = styled(Box)<ViewContentCenterType>(({ theme, nopadding }) => ({
  margin: '0 auto',
  width: '100%',
  paddingTop: '40px',
  paddingRight: '80px',
  [theme.breakpoints.up('xl')]: {
    width: '800px'
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: nopadding === 'true' ? '0' : '24px',
    paddingRight: nopadding === 'true' ? '0' : '24px',
    paddingTop: '32px'
  }
}));

export const ViewSidebar = styled(Box)<ViewSidebarProps>(({ theme, withimage }) => ({
  [theme.breakpoints.up('md')]: {
    backgroundImage: withimage === 'true' ? `url(${Orquidea})` : '',
    backgroundSize: withimage === 'true' ? 'auto' : '',
    backgroundRepeat: withimage === 'true' ? 'no-repeat' : '',
    backgroundPosition: withimage === 'true' ? '100% 70%;' : ''
  },
  backgroundColor: theme.palette.brand.darkGreen,
  width: '500px',
  minHeight: 'calc(100vh - 142px)',
  padding: '56px 80px 0 80px',
  [theme.breakpoints.down('md')]: {
    position: 'sticky',
    top: '-1px',
    minHeight: 'auto',
    padding: '24px 24px 24px 24px',
    width: '100%'
  }
}));

import { createTheme } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    inline: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    large: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body1B: React.CSSProperties;
    body1R: React.CSSProperties;
    body2R: React.CSSProperties;
    body2B: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    body6: React.CSSProperties;
    body7: React.CSSProperties;
    subtitle4: React.CSSProperties;
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body1B: React.CSSProperties;
    body1R: React.CSSProperties;
    body2R: React.CSSProperties;
    body2B: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    body6: React.CSSProperties;
    body7: React.CSSProperties;
    subtitle4: React.CSSProperties;
  }
  interface Palette {
    neutrals: {
      beige1: React.CSSProperties['color'];
      beige2: React.CSSProperties['color'];
      beige3: React.CSSProperties['color'];
      beige4: React.CSSProperties['color'];
      lead: React.CSSProperties['color'];
    };
    brand: {
      darkGreen: React.CSSProperties['color'];
      mediumGreen: React.CSSProperties['color'];
      lightGreen: React.CSSProperties['color'];
    };
    colored: {
      darkBlue: React.CSSProperties['color'];
      lightBlue: React.CSSProperties['color'];
      purple: React.CSSProperties['color'];
      lilac: React.CSSProperties['color'];
      red: React.CSSProperties['color'];
      salmon: React.CSSProperties['color'];
      yellow: React.CSSProperties['color'];
      green2: React.CSSProperties['color'];
    };
    colored_variation: {
      darkBlue: React.CSSProperties['color'];
      lightBlue: React.CSSProperties['color'];
      purple: React.CSSProperties['color'];
      salmon: React.CSSProperties['color'];
      darkGreen: React.CSSProperties['color'];
      lightGreen: React.CSSProperties['color'];
    };
    services: {
      darkBlue: React.CSSProperties['color'];
      lightBlue: React.CSSProperties['color'];
      purple: React.CSSProperties['color'];
      lilac: React.CSSProperties['color'];
      red: React.CSSProperties['color'];
      salmon: React.CSSProperties['color'];
    };
    status: {
      danger: React.CSSProperties['color'];
    };
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutrals?: {
      beige1: React.CSSProperties['color'];
      beige2: React.CSSProperties['color'];
      beige3: React.CSSProperties['color'];
      beige4: React.CSSProperties['color'];
      lead: React.CSSProperties['color'];
    };
    brand?: {
      darkGreen: React.CSSProperties['color'];
      mediumGreen: React.CSSProperties['color'];
      lightGreen: React.CSSProperties['color'];
    };
    colored?: {
      darkBlue: React.CSSProperties['color'];
      lightBlue: React.CSSProperties['color'];
      purple: React.CSSProperties['color'];
      lilac: React.CSSProperties['color'];
      red: React.CSSProperties['color'];
      salmon: React.CSSProperties['color'];
      yellow: React.CSSProperties['color'];
      green2: React.CSSProperties['color'];
    };
    colored_variation?: {
      darkBlue: React.CSSProperties['color'];
      lightBlue: React.CSSProperties['color'];
      purple: React.CSSProperties['color'];
      salmon: React.CSSProperties['color'];
      darkGreen: React.CSSProperties['color'];
      lightGreen: React.CSSProperties['color'];
    };
    services?: {
      darkBlue: React.CSSProperties['color'];
      lightBlue: React.CSSProperties['color'];
      purple: React.CSSProperties['color'];
      lilac: React.CSSProperties['color'];
      red: React.CSSProperties['color'];
      salmon: React.CSSProperties['color'];
    };
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1B: true;
    body1R: true;
    body2R: true;
    body2B: true;
    body3: true;
    body4: true;
    body5: true;
    body6: true;
    body7: true;
    subtitle4: true;
  }
}

// Create a theme instance.
let theme = createTheme();
theme = createTheme(theme, {
  palette: {
    error: {
      main: '#E36F7B'
    },
    success: {
      main: '#191919'
    },
    neutrals: {
      beige1: '#F0EEE4',
      beige2: '#E8E5D7',
      beige3: '#DEDAC9',
      beige4: '#B4B09E',
      lead: '#191919'
    },
    brand: {
      darkGreen: '#0C3B49',
      mediumGreen: '#178E8E',
      lightGreen: '#A7DBC4'
    },
    colored: {
      darkBlue: '#0F1E53',
      lightBlue: '#5B7BE5',
      purple: '#47254A',
      lilac: '#D5A2C8',
      red: '#6A0000',
      salmon: '#E36F7B',
      yellow: '#FFCA76',
      green2: '#082933'
    },
    colored_variation: {
      darkBlue: '#0b163d',
      lightBlue: '#718de8',
      purple: '#371d39',
      salmon: '#e7848f',
      darkGreen: '#244e5b',
      lightGreen: '#b9e2d0'
    },
    services: {
      darkBlue: '#0F1E53',
      lightBlue: '#5B7BE5',
      purple: '#47254A',
      lilac: '#D5A2C8',
      red: '#6A0000',
      salmon: '#E36F7B'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 478,
      md: 991,
      lg: 1440,
      xl: 1441
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'F Grotesk Book',
    h1: {
      fontFamily: 'Juana Alt',
      fontWeight: 300,
      fontSize: '3rem',
      lineHeight: '3.45rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5rem',
        lineHeight: '2.4rem'
      }
    },
    h2: {
      fontFamily: 'Juana Alt',
      fontWeight: 300,
      fontSize: '2.5rem',
      lineHeight: '2.875rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
        lineHeight: '2.3rem'
      }
    },
    h3: {
      fontFamily: 'Juana Alt',
      fontWeight: 300,
      fontSize: '2rem',
      lineHeight: '2.5rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.75rem',
        lineHeight: '2.125rem'
      }
    },
    h4: {
      fontFamily: 'Juana Alt',
      fontWeight: 300,
      fontSize: '1.5rem',
      lineHeight: '1.24rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
        lineHeight: '1.24'
      }
    },
    // body1R
    body1: {
      fontFamily: 'F Grotesk Book',
      fontWeight: 400,
      lineHeight: '1.36rem'
    },
    body1R: {
      fontFamily: 'F Grotesk Book',
      fontWeight: 400,
      lineHeight: '1.0rem'
    },
    body1B: {
      fontFamily: 'F Grotesk Book',
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1.0rem'
    },
    body2R: {
      fontFamily: 'F Grotesk Book',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.20rem',
      letterSpacing: '-0.14px'
    },
    body2B: {
      fontFamily: 'F Grotesk Demi',
      fontWeight: 700,
      fontSize: '0.875rem',
      lineHeight: '0.875rem',
      letterSpacing: '0'
    },
    body3: {
      fontFamily: 'F Grotesk Thin Italic',
      fontStyle: 'italic',
      fontWeight: 400,
      lineHeight: '1.45rem',
      [theme.breakpoints.down('sm')]: {
        fontWeight: 600
      }
    },
    body4: {
      fontFamily: 'F Grotesk Book',
      fontWeight: 400,
      lineHeight: '1.45rem',
      [theme.breakpoints.down('sm')]: {
        fontWeight: 600
      }
    },
    body5: {
      fontFamily: 'F Grotesk Book Italic',
      fontWeight: 400,
      fontStyle: 'italic',
      lineHeight: '0.75rem'
    },
    body6: {
      fontFamily: 'F Grotesk Book',
      fontWeight: 400,
      lineHeight: '0.924rem',
      fontSize: '0.875rem',
      letterSpacing: '-0.14px'
    },
    body7: {
      fontFamily: 'F Grotesk Book',
      fontWeight: 400,
      lineHeight: '0.938rem',
      fontSize: '0.75rem'
    },
    subtitle1: {
      fontFamily: 'F Grotesk Thin',
      fontWeight: 250,
      fontSize: '1.75rem',
      lineHeight: '2.17rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
        lineHeight: '1.86rem'
      }
    },
    subtitle2: {
      fontFamily: 'F Grotesk Book',
      fontWeight: 400,
      lineHeight: '1.36rem'
    },
    subtitle4: {
      fontFamily: 'F Grotesk Book',
      fontWeight: 400,
      fontSize: '2.5rem',
      lineHeight: '1.16rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5rem',
        lineHeight: '1.16rem'
      }
    },
    overline: {
      fontFamily: 'Caveat',
      fontWeight: 400,
      fontSize: '1.75rem',
      lineHeight: '1.505rem'
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1B: 'p',
          body1R: 'p',
          body2R: 'p',
          body2B: 'p',
          body3: 'p',
          body4: 'p',
          body5: 'p',
          body6: 'p',
          body7: 'p',
          subtitle4: 'span'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
      html {
       scroll-behavior: smooth;
      }

        /* f_grotesk_thin */
        @font-face {
          font-family: 'F Grotesk Thin';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('/fonts/familjen-grotesk/f_grotesk_thin.eot'); /* IE9 Compat Modes */
          src: local(''),
            url('/fonts/familjen-grotesk/f_grotesk_thin.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */ url('/fonts/familjen-grotesk/f_grotesk_thin.woff2') format('woff2'),
            /* Super Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_thin.woff') format('woff'),
            /* Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_thin.ttf') format('truetype'),
            /* Safari, Android, iOS */ url('/fonts/familjen-grotesk/f_grotesk_thin.svg#FamiljenGrotesk')
              format('svg'); /* Legacy iOS */
        }

        /* f_grotesk_thin_italic */
        @font-face {
          font-family: 'F Grotesk Thin Italic';
          font-style: italic;
          font-display: swap;
          font-weight: 400;
          src: url('/fonts/familjen-grotesk/f_grotesk_thin_italic.eot'); /* IE9 Compat Modes */
          src: local(''), url('/fonts/familjen-grotesk/f_grotesk_thin_italic.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */ url('/fonts/familjen-grotesk/f_grotesk_thin_italic.woff2') format('woff2'),
            /* Super Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_thin_italic.woff') format('woff'),
            /* Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_thin_italic.ttf') format('truetype'),
            /* Safari, Android, iOS */ url('/fonts/familjen-grotesk/f_grotesk_thin_italic.svg#Caveat') format('svg'); /* Legacy iOS */
        }

        /* f_grotesk_book */
        @font-face {
          font-family: 'F Grotesk Book';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('/fonts/familjen-grotesk/f_grotesk_book.eot'); /* IE9 Compat Modes */
          src: local(''), url('/fonts/familjen-grotesk/f_grotesk_book.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */ url('/fonts/familjen-grotesk/f_grotesk_book.woff2') format('woff2'),
            /* Super Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_book.woff') format('woff'),
            /* Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_book.ttf') format('truetype'),
            /* Safari, Android, iOS */ url('/fonts/familjen-grotesk/f_grotesk_book.svg#Caveat') format('svg'); /* Legacy iOS */
        }

        /* f_grotesk_book_italic */
        @font-face {
          font-family: 'F Grotesk Book Italic';
          font-style: italic;
          font-display: swap;
          font-weight: 400;
          src: url('/fonts/familjen-grotesk/f_grotesk_book_italic.eot'); /* IE9 Compat Modes */
          src: local(''), url('/fonts/familjen-grotesk/f_grotesk_book_italic.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */ url('/fonts/familjen-grotesk/f_grotesk_book_italic.woff2') format('woff2'),
            /* Super Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_book_italic.woff') format('woff'),
            /* Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_book_italic.ttf') format('truetype'),
            /* Safari, Android, iOS */ url('/fonts/familjen-grotesk/f_grotesk_book_italic.svg#Caveat') format('svg'); /* Legacy iOS */
        }


        /* f_grotesk_demi */
        @font-face {
          font-family: 'F Grotesk Demi';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('/fonts/familjen-grotesk/f_grotesk_demi.eot'); /* IE9 Compat Modes */
          src: local(''),
            url('/fonts/familjen-grotesk/f_grotesk_demi.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */ url('/fonts/familjen-grotesk/f_grotesk_demi.woff2') format('woff2'),
            /* Super Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_demi.woff') format('woff'),
            /* Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_demi.ttf') format('truetype'),
            /* Safari, Android, iOS */ url('/fonts/familjen-grotesk/f_grotesk_demi.svg#FamiljenGrotesk')
              format('svg'); /* Legacy iOS */
        }

        /* f_grotesk_demi_italic */
        @font-face {
          font-family: 'F Grotesk Demi Italic';
          font-style: italic;
          font-display: swap;
          font-weight: 400;
          src: url('/fonts/familjen-grotesk/f_grotesk_demo_italic.eot'); /* IE9 Compat Modes */
          src: local(''),
            url('/fonts/familjen-grotesk/f_grotesk_demo_italic.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */ url('/fonts/familjen-grotesk/f_grotesk_demo_italic.woff2') format('woff2'),
            /* Super Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_demo_italic.woff') format('woff'),
            /* Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_demo_italic.ttf') format('truetype'),
            /* Safari, Android, iOS */ url('/fonts/familjen-grotesk/f_grotesk_demo_italic.svg#FamiljenGrotesk')
              format('svg'); /* Legacy iOS */
        }

        /* f_grotesk_bold */
        @font-face {
          font-family: 'F Grotesk Bold';
          font-style: italic;
          font-display: swap;
          font-weight: 600;
          src: url('/fonts/familjen-grotesk/f_grotesk_bold.eot'); /* IE9 Compat Modes */
          src: local(''),
            url('/fonts/familjen-grotesk/f_grotesk_bold.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */ url('/fonts/familjen-grotesk/f_grotesk_bold.woff2') format('woff2'),
            /* Super Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_bold.woff') format('woff'),
            /* Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_bold.ttf') format('truetype'),
            /* Safari, Android, iOS */ url('/fonts/familjen-grotesk/f_grotesk_bold.svg#FamiljenGrotesk')
              format('svg'); /* Legacy iOS */
        }

        /* f_grotesk_bold_italic */
        @font-face {
          font-family: 'F Grotesk Bold Italic';
          font-style: italic;
          font-display: swap;
          font-weight: 600;
          src: url('/fonts/familjen-grotesk/f_grotesk_bold_italic.eot'); /* IE9 Compat Modes */
          src: local(''),
            url('/fonts/familjen-grotesk/f_grotesk_bold_italic.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */ url('/fonts/familjen-grotesk/f_grotesk_bold_italic.woff2') format('woff2'),
            /* Super Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_bold_italic.woff') format('woff'),
            /* Modern Browsers */ url('/fonts/familjen-grotesk/f_grotesk_bold_italic.ttf') format('truetype'),
            /* Safari, Android, iOS */ url('/fonts/familjen-grotesk/f_grotesk_bold_italic.svg#FamiljenGrotesk')
              format('svg'); /* Legacy iOS */
        }

        /* caveat-regular - latin */
        @font-face {
          font-family: 'Caveat';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('/fonts/caveat/caveat-v17-latin-regular.eot'); /* IE9 Compat Modes */
          src: local(''), url('/fonts/caveat/caveat-v17-latin-regular.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */ url('/fonts/caveat/caveat-v17-latin-regular.woff2') format('woff2'),
            /* Super Modern Browsers */ url('/fonts/caveat/caveat-v17-latin-regular.woff') format('woff'),
            /* Modern Browsers */ url('/fonts/caveat/caveat-v17-latin-regular.ttf') format('truetype'),
            /* Safari, Android, iOS */ url('/fonts/caveat/caveat-v17-latin-regular.svg#Caveat') format('svg'); /* Legacy iOS */
        }

        /* juana-regular - latin */
        @font-face {
          font-family: 'Juana Alt';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('/fonts/juana/juanaalt-regular.eot'); /* IE9 Compat Modes */
          src: local(''), url('/fonts/juana/juanaalt-regular.eot?#iefix') format('embedded-opentype'),
            /* IE6-IE8 */ url('/fonts/juana/juanaalt-regular.woff2') format('woff2'),
            /* Super Modern Browsers */ url('/fonts/juana/juanaalt-regular.woff') format('woff'),
            /* Modern Browsers */ url('/fonts/juana/juanaalt-regular.ttf') format('truetype'),
            /* Safari, Android, iOS */ url('/fonts/juana/juanaalt-regular.svg#juana') format('svg'); /* Legacy iOS */
        }
      `
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        disableFocusRipple: true
      },
      variants: [
        {
          props: { variant: 'inline', color: 'primary' }
        }
      ],
      styleOverrides: {
        root: ({ ownerState, theme }: any) => ({
          textTransform: 'initial',
          fontFamily: 'F Grotesk Demi',
          fontWeight: 600,
          lineHeight: '1.36rem',
          padding: '13px 24px',
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: theme.palette.brand.lightGreen,
              color: theme.palette.brand.darkGreen,
              borderRadius: '100px',
              '&:hover': {
                backgroundColor: theme.palette.colored_variation.lightGreen
              },
              '&:disabled': {
                backgroundColor: `${theme.palette.colored_variation.lightGreen}44`
              }
            }),

          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'inherit' && {
              backgroundColor: theme.palette.brand.darkGreen,
              color: theme.palette.brand.lightGreen,
              textAlign: 'left',
              borderRadius: '100px',
              '&:hover': {
                backgroundColor: theme.palette.colored_variation.darkGreen
              },
              '&:disabled': {
                backgroundColor: `${theme.palette.colored_variation.darkGreen}44`
              }
            }),

          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'secondary' && {
              backgroundColor: theme.palette.neutrals.beige3,
              color: theme.palette.brand.darkGreen,
              borderRadius: '100px',
              '&:hover': {
                backgroundColor: theme.palette.neutrals.beige2
              },
              '&:disabled': {
                backgroundColor: `${theme.palette.neutrals.beige2}44`
              }
            }),

          ...(ownerState.variant === 'inline' &&
            ownerState.color === 'primary' && {
              color: theme.palette.brand.darkGreen,
              borderBottom: `solid 4px ${theme.palette.brand.lightGreen}`,
              borderRadius: 0,
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 0,
              '&:hover': {
                backgroundColor: 'transparent',
                color: `${theme.palette.brand.darkGreen}85`,
                borderBottom: `solid 4px ${theme.palette.brand.lightGreen}85`
              }
            })
        })
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: () => ({
          borderBottom: `1px solid ${theme.palette.neutrals.beige1}`,
          'input:-webkit-autofill,input:-webkit-autofill:hover, input:-webkit-autofill:focus': {
            transition: 'background-color 5000s ease-in-out 0s'
          }
        })
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: () => ({
          '&.Mui-checked': {
            color: theme.palette.brand.darkGreen
          }
        })
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: () => ({
          '&.Mui-checked': {
            color: theme.palette.brand.darkGreen
          }
        })
      }
    },
    MuiInput: {
      styleOverrides: {
        root: () => ({
          fontSize: '1rem',
          fontWeight: 600,
          color: theme.palette.neutrals.lead,
          '&::after': {
            borderBottom: `2px solid ${theme.palette.brand.lightGreen}`
          },
          input: {
            '&::placeholder': {
              color: theme.palette.neutrals.beige4,
              opacity: '100%'
            },
            caretColor: theme.palette.brand.lightGreen
          }
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.neutrals.lead,
          '&.Mui-focused': {
            color: theme.palette.neutrals.lead
          }
        })
      }
    },
    MuiLink: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.neutrals.lead,
          textDecorationColor: theme.palette.neutrals.lead,
          ['a:link']: {
            color: theme.palette.neutrals.lead
          }
        })
      }
    },
    MuiNativeSelect: {
      styleOverrides: {
        icon: () => ({
          color: theme.palette.neutrals.beige1
        })
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'large' }
        }
      ],
      styleOverrides: {
        root: ({ ownerState, theme }: any) => ({
          ...(ownerState.variant === 'outlined' && {
            fontSize: '1rem',
            fontFamily: 'F Grotesk Book',
            fontWeight: 400,
            lineHeight: '1.36rem',
            borderColor: theme.palette.neutrals.beige4,
            backgroundColor: 'transparent',
            color: theme.palette.brand.darkGreen,
            '&.MuiChip-clickable:hover': {
              backgroundColor: theme.palette.brand.darkGreen,
              borderColor: theme.palette.brand.darkGreen,
              color: theme.palette.neutrals.beige1
            }
          }),

          ...(ownerState.variant === 'large' && {
            fontSize: '1rem',
            fontFamily: 'F Grotesk Book',
            fontWeight: 400,
            lineHeight: '2.3rem',
            borderColor: theme.palette.neutrals.beige4,
            borderWidth: '1px',
            borderStyle: 'solid',
            height: '56px',
            backgroundColor: 'transparent',
            color: theme.palette.brand.darkGreen,
            padding: '8px 24px',
            '&:hover': {
              backgroundColor: theme.palette.brand.darkGreen,
              borderColor: theme.palette.brand.darkGreen,
              color: theme.palette.neutrals.beige1,
              cursor: 'pointer'
            },
            '&.MuiChip-clickable:hover': {
              backgroundColor: theme.palette.brand.darkGreen,
              borderColor: theme.palette.brand.darkGreen,
              color: theme.palette.neutrals.beige1
            }
          })
        })
      }
    },
    MuiDayPicker: {
      styleOverrides: {
        weekDayLabel: () => ({
          fontWeight: 600
        })
      }
    },
    MuiPickersDay: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: ({ theme }: any) => ({
          backgroundColor: 'transparent',
          '&.Mui-disabled': {
            textDecoration: 'line-through',
            backgroundColor: 'transparent'
          },
          '&.Mui-selected': {
            border: `1px solid transparent`,
            backgroundColor: theme.palette.brand.lightGreen + '!important',
            color: theme.palette.brand.darkGreen,

            '&:hover': {
              backgroundColor: theme.palette.brand.lightGreen
            }
          }
        })
      }
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          textTransform: 'capitalize'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }: any) => ({
          backgroundColor: 'transparent',
          '&.Mui-disabled': {
            textDecoration: 'line-through',
            backgroundColor: 'transparent'
          },
          '&.Mui-selected': {
            border: `1px solid ${theme.palette.brand.darkGreen}`,
            backgroundColor: 'transparent',
            color: theme.palette.brand.darkGreen
          }
        })
      }
    }
  }
});

export default theme;

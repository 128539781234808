import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AvailableSchedules } from './styled';
import { useAppointmentsAvailable, useScheduleAvailableTimeGroupedByPeriod } from './hooks';
import AvailableDays from './AvailableDays';
import AvailableTimes from './AvailableTimes';
import { RootState } from '../../../store';
import { customEventTracking } from '../../../lib/gtm';
import { hasScheduleAvailableTimes } from '../../../lib/dayjs';
import { resetSchedule } from '../../../features/schedule/schedule-slice';
import { goTo, updateValidStep } from '../../../features/stepper/stepper-slice';
import { resetPayment } from '../../../features/payment/payment-slice';
let firstTime = true;

const Schedules = () => {
  const dispatch = useDispatch();
  const { setCurrentMonth, setCurrentYear, appointments, isLoading } = useAppointmentsAvailable();
  const { firstDaysAvailable, dateStart } = useSelector((state: RootState) => state.schedule);
  const [firstDayAvailableTimes] = useScheduleAvailableTimeGroupedByPeriod(appointments, firstDaysAvailable[0]);
  const [secondDayAvailableTimes] = useScheduleAvailableTimeGroupedByPeriod(appointments, firstDaysAvailable[1]);
  const apoointmentsCached = useMemo(() => appointments, [appointments]);

  //Google Tag Manager - Event Tracking
  useEffect(() => {
    if (
      firstTime &&
      hasScheduleAvailableTimes(firstDayAvailableTimes) &&
      hasScheduleAvailableTimes(secondDayAvailableTimes)
    ) {
      customEventTracking.send({
        event: 'calendar_on_ready',
        props: {
          first_days_available: firstDaysAvailable,
          first_day_available_times: `morning: ${
            firstDayAvailableTimes?.morning?.availableList.length > 0 ? true : false
          }, afternoon: ${firstDayAvailableTimes?.afternoon?.availableList.length > 0 ? true : false}, evening: ${
            firstDayAvailableTimes?.evening?.availableList.length > 0 ? true : false
          }`,
          second_day_available_times: `morning: ${
            secondDayAvailableTimes?.morning?.availableList.length > 0 ? true : false
          }, afternoon: ${secondDayAvailableTimes?.afternoon?.availableList.length > 0 ? true : false}, evening: ${
            secondDayAvailableTimes?.evening?.availableList.length > 0 ? true : false
          }`
        }
      });
      firstTime = false;
    }
  }, [firstDayAvailableTimes, secondDayAvailableTimes]);

  useEffect(() => {
    const isSelectedDateAvailable = () => {
      return (
        dateStart.length > 0 &&
        apoointmentsCached.availableList.length > 0 &&
        !apoointmentsCached.availableList.includes(dateStart)
      );
    };

    if (isSelectedDateAvailable()) {
      dispatch(resetSchedule());
      dispatch(goTo('/dados-pessoais'));
      dispatch(updateValidStep({ schedule: false }));
    }
  }, [apoointmentsCached]);

  useEffect(() => {
    dispatch(resetPayment());
  }, []);
  return (
    <AvailableSchedules>
      <AvailableDays
        appointments={appointments}
        updateMonth={setCurrentMonth}
        updateYear={setCurrentYear}
        isLoading={isLoading}
      />
      <AvailableTimes appointments={appointments} isLoading={isLoading} />
    </AvailableSchedules>
  );
};

export default Schedules;

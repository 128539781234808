import { styled } from '@mui/material/styles';
import MuiBox from '@mui/material/Box';
import MuiGrid from '@mui/material/Unstable_Grid2';
import MuiContainer, { ContainerProps } from '@mui/material/Container';

export const SectionRoot = styled(MuiBox)(() => ({
  backgroundColor: 'transparent'
}));

export const SectionGrid = styled(MuiGrid)(() => ({
  margin: 'auto'
}));

export const InnerSectionContainer = styled((props: ContainerProps) => <MuiContainer disableGutters {...props} />)(
  ({ theme }) => ({
    padding: '0 80px',
    [theme.breakpoints.down('md')]: {
      padding: '0 40px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 24px'
    }
  })
);

export const SectionRootFullWidth = styled((props: ContainerProps) => <MuiContainer disableGutters {...props} />)(
  ({ theme }) => ({
    padding: '80px',
    height: 'calc(100vh - 142px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 158px)',
      paddingTop: '54px',
      paddingBottom: '54px',
      paddingLeft: '24px',
      paddingRight: '24px'
    },
  })
);

export const SectionFullWidth = styled(MuiBox)(({ theme }) => ({
  width: '100%',
  padding: '0 80px',
  [theme.breakpoints.down('md')]: {
    padding: '0 40px'
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 24px'
  }
}));

export const InnerSectionBox = styled(MuiBox)(({ theme }) => ({
  padding: '80px 0',
  [theme.breakpoints.down('md')]: {
    padding: '56px 0'
  }
}));

import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

const SessionLimiterStyled = styled(Divider)(({ theme }) => () => ({
  backgroundColor: theme.palette.neutrals.beige3,
  height: '1px',
  borderBottom: '0px',
  marginBottom: '24px',
  marginTop: '28px'
}));
export default SessionLimiterStyled;

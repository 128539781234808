import { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { BoxStyled } from './style';

interface ModalProps {
  title?: string | undefined;
  open: boolean;
  onCloseHandler: () => void;
}

const BasicModal = ({ open, onCloseHandler, title, children }: PropsWithChildren<ModalProps>) => {
  return (
    <Modal
      keepMounted
      open={open}
      onClose={onCloseHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <BoxStyled>
        <CloseIcon onClick={onCloseHandler} sx={{ cursor: 'pointer' }} data-testid="icon-close" />
        {title && (
          <Typography id="modal-modal-title" variant="h2" sx={{ mt: 2 }}>
            {title}
          </Typography>
        )}
        <Box id="modal-modal-description" sx={{ mt: 2 }}>
          {children}
        </Box>
      </BoxStyled>
    </Modal>
  );
};

export default BasicModal;

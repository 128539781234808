import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';

export const Tag = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.beige3,
  borderRadius: '4px;',
  padding: '5px 11px'
}));

export const CardStyled = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.neutrals.beige4}`,
  backgroundColor: theme.palette.neutrals.beige1,
  width: '100%',
  ':hover': {
    border: `1px solid ${theme.palette.neutrals.lead}`,
    cursor: 'pointer'
  }
}));

import Typography from '@mui/material/Typography';
import { ViewRoot, ViewContent, ViewContentCenter, ViewSidebar } from '../../layout/Main/styled';
import { useNavigate } from 'react-router-dom';
import { CardGridStyled, LinkStyled } from './styled';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { enabledBackButtonOnSchedulePage, saveCurrentStep } from '../../features/stepper/stepper-slice';
import ButtonNeed from '../../components/ButtonNeed';
import { INeedsResult, useGetNeedsMutation } from '../../services/needs.service';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const NeedList = () => {
  const [getNeedsMutation, { data, isLoading }] = useGetNeedsMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getNeedsMutation('');
    dispatch(saveCurrentStep('NeedList'));
    dispatch(enabledBackButtonOnSchedulePage(true));
  }, []);

  return (
    <>
      <ViewRoot>
        <ViewContent className="ViewContent">
          <ViewContentCenter>
            <Box mb={3}>
              <Box mb={3}>
                <Typography variant="h3">Estou interessada em...</Typography>
              </Box>
              {!isLoading ? <></> : <CircularProgress sx={{ color: 'brand.darkGreen' }} />}
              <CardGridStyled>
                {data?.map((value: INeedsResult) => {
                  return (
                    <Box key={value.name} sx={{ mb: { xs: 2, md: 1 } }}>
                      <LinkStyled target="_self" onClick={() => navigate(`/selecao-de-servicos/${value.route}`)}>
                        <ButtonNeed title={value.name} />
                      </LinkStyled>
                    </Box>
                  );
                })}
              </CardGridStyled>
            </Box>
            <Box mb={5} mt={3}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ padding: '12px 24px 14px 16px' }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
                <Typography variant="body1" sx={{ ml: 1.5 }}>
                  Voltar
                </Typography>
              </Button>
            </Box>
          </ViewContentCenter>
        </ViewContent>
        <ViewSidebar withimage="true" component="aside" className="ViewSidebar">
          <Typography color="neutrals.beige1" variant="h1">
            O que você precisa hoje?
          </Typography>
        </ViewSidebar>
      </ViewRoot>
    </>
  );
};

export default NeedList;

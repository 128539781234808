import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

export const StepperNumbers = styled(Box)(({ theme }) => ({
  background: theme.palette.neutrals.beige3,
  borderRadius: 4
}));

export const AlertStyled = styled(Alert)(({ theme }) => ({
  '.MuiAlert-action': {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.brand.lightGreen
  }
}));

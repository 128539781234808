import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import { ButtonMoreLess, Wrapper } from './styled';
import Typography from '@mui/material/Typography';
import medic01 from '../../../images/medic01.png';
import medic02 from '../../../images/medic02.png';
import medic03 from '../../../images/medic03.png';
import medic04 from '../../../images/medic04.png';
import medic05 from '../../../images/medic05.png';
import medic06 from '../../../images/medic06.png';
import Collapse from '@mui/material/Collapse';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';
import { useDispatch, useSelector } from 'react-redux';
import { saveCalendarDetails } from '../../../features/product/product-slice';
import { RootState } from '../../../store';
import { customEventTracking } from '../../../lib/gtm';

interface ListMedicInfo {
  name: string;
  infoDocs: JSX.Element;
  userId: string;
  subTitle: string;
  image: string;
  text?: JSX.Element;
}

const listMedicInfo: ListMedicInfo[] = [
  {
    name: 'Dra. Natalia Ramos',
    userId: process.env.REACT_APP_ID_DOCTOR_NATALIA_RAMOS || '',
    infoDocs: (
      <>
        <b>CRM</b> 169.753 | <b>RQE</b> 85.112
      </>
    ),
    subTitle: 'Ginecologista especialista em fertilidade',
    image: medic01,
    text: (
      <>
        <Typography variant="body2R" mb={1}>
          Médica ginecologista e obstetra, especialista em fertilidade e líder da Equipe de Cuidado da Oya. Workaholic em
          recuperação e mãe da Maria (#vemaí!). Quando não está trabalhando pode ser encontrada com sua família ou em
          passeios ao livre, sempre com um livro a tiracolo. Graduação em Medicina pela Universidade Nove de Julho, com
          residência médica em Ginecologia e Obstetrícia pelo Hospital Pérola Byington. Especialização em Reprodução Humana
          pelo Hospital Foch (França).
        </Typography>
        <Typography variant="body2R">
          “Me frustrava muito ver como as pessoas descobrem a fertilidade - a partir da infertilidade. Por que ninguém fala
          em aconselhamento preventivo de fertilidade? Por que ninguém explica como a fertilidade funciona, para que as
          decisões sobre maternidade sejam tomadas com informação? Foi assim que cheguei na Oya - por uma questão de
          propósito.”
        </Typography>
      </>
    )
  },
  {
    name: 'Dra. Ana Flávia Hostalácio',
    userId: process.env.REACT_APP_ID_DOCTOR_ANA_FLAVIA || '',
    infoDocs: (
      <>
        <b>CRM</b> 180.810 | <b>RQE</b> 86.160
      </>
    ),
    subTitle: 'Ginecologista especialista em Fertilidade',
    image: medic02,
    text: (
      <>
        <Typography variant="body2R" mb={1}>
          Virginiana perfeccionista e metódica, que se encontra na sua bagunça organizada. Planejamento é sua meta diária e
          ai se algo ou alguém desencarrilha esse trilho. De um coração gigante, ama estar em família, entre amigos e pessoas
          que gosta, seja pra um passeio na cidade ou uma aventura na natureza. Ginecologista e Obstetra pela Faculdade de
          Medicina de Jundiaí e especialista em Reprodução Humana pela Faculdade de Medicina do ABC. Atuação na área de
          Infertilidade e Reprodução Humana no Instituto Ideia Fértil de Saúde Reprodutiva.
        </Typography>
        <Typography variant="body2R">
          “Aposto nas diversas formas de acolhimento e proximidade, mas acima de tudo me identifico com a missão da Oya.
          Acredito que através do conhecimento alcançamos autonomia e ferramentas para o autocuidado. Poder fornecer esse
          aconselhamento, estar ao lado nesta busca e testemunhar a liberdade da fertilidade é gratificante.”
        </Typography>
      </>
    )
  },
  {
    name: 'Dra. Caroline Ingold',
    userId: process.env.REACT_APP_ID_DOCTOR_CAROLINA_INGOLD || '',
    infoDocs: (
      <>
        <b>CRM</b> 190.554 | <b>RQE</b> 100.987
      </>
    ),
    subTitle: 'Ginecologista especialista em Fertilidade',
    image: medic03,
    text: (
      <>
        <Typography variant="body2R" mb={1}>
          Médica ginecologista e obstetra fazendo especialização na área de fertilidade. Apaixonada por seus dois pets: Jambo
          e Kiara. Foi destaque no basquete feminino universitário e hoje valoriza o tempo de qualidade com a família.
          Graduação em Medicina e residência médica em Ginecologia e Obstetrícia pela Faculdade de Medicina do ABC.
          Fellowship (estágio de especialização) em Reprodução Humana pelo Instituto Ideia Fértil.
        </Typography>
        <Typography variant="body2R">
          “Ser ginecologista é tratar as pessoas do sexo feminino de maneira global, acompanhar da adolescência até o final
          da vida. Por isso acredito não só em olhar para as queixas específicas, vejo que boa parte delas estão mais ligadas
          à necessidade de ser ouvida. Mais do que uma medicação, a pessoa chega no consultório buscando uma orientação, uma
          conversa sobre o que está sentindo.”
        </Typography>
      </>
    )
  },
  {
    name: 'Dra. Amanda M. Fonseca',
    userId: process.env.REACT_APP_ID_DOCTOR_AMANDA_FONSECA || '',
    infoDocs: (
      <>
        <b>CRM</b> 177.539 | <b>RQE</b> 90.826
      </>
    ),
    subTitle: 'Ginecologista especialista em Fertilidade',
    image: medic04,
    text: (
      <>
        <Typography variant="body2R" mb={1}>
          Mamãe do Bernardo, é apaixonada por música e está sempre em busca do equilíbrio entre trabalho, saúde e bem-estar.
          Foco no momento presente é o seu lema. Médica ginecologista, fez residência médica em Ginecologista e é
          especialista em Reprodução Humana, Infertilidade conjugal, Endometriose e Anticoncepção.
        </Typography>
        <Typography variant="body2R">
          “Me sinto feliz em cada vez mais levar informação de qualidade a um público tão especial. Encontrei na Oya uma
          ferramenta incrível e versátil para alcançar um público cada vez mais diversificado.”
        </Typography>
      </>
    )
  },
  {
    name: 'Dra. Thaís Luzo',
    userId: process.env.REACT_APP_ID_DOCTOR_THAIS_LUZO || '',
    infoDocs: (
      <>
        <b>CRM</b> 201.330 | <b>RQE</b> 100.817
      </>
    ),
    subTitle: 'Ginecologista especialista em Fertilidade',
    image: medic05
  },
  {
    name: 'Dra. Gabriela Prata',
    userId: process.env.REACT_APP_ID_DOCTOR_GABRIELA_PRATA || '',
    infoDocs: (
      <>
        <b>CRM</b> 192.635 | <b>RQE</b> 100.999
      </>
    ),
    subTitle: 'Ginecologista especialista em Fertilidade',
    image: medic06
  }
];

export const CardDoctor: FC = () => {
  const { product } = useSelector((state: RootState) => state);
  const [openViewMore, setOpenViewMore] = useState<number | null>(null);
  const dispatch = useDispatch();
  const navegate = useNavigate();
  const handleClick = (id: number) => {
    setOpenViewMore(openViewMore => (openViewMore === id ? null : id));
  };

  return (
    <>
      {listMedicInfo.map((resp, key) => {
        return (
          <Wrapper mb={3} key={resp.name}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Box sx={{ display: 'flex' }}>
                <Box>
                  <CardMedia src={resp.image} component="img" sx={{ width: '64px', borderRadius: '10px' }} />
                </Box>
                <Box ml={3}>
                  <Typography variant="h4" mb={1}>
                    {resp.name}
                  </Typography>
                  <Typography variant="body6" mb={0.5}>
                    {resp.infoDocs}
                  </Typography>
                  <Typography variant="body2R">{resp.subTitle}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Button
                  variant="contained"
                  sx={{ padding: '12px 16px 14px 24px' }}
                  onClick={() => {
                    customEventTracking.send({ event: resp.name });
                    dispatch(
                      saveCalendarDetails({
                        id: product.calendar.id,
                        calendarType: product.calendar.calendarType,
                        durationEvent: product.calendar.durationEvent,
                        userId: resp.userId
                      })
                    );
                    navegate(`/servico/${product.id}/especialista/${resp.userId}`);
                  }}
                >
                  <Typography variant="body1B" sx={{ mr: 1.5 }}>
                    Selecionar
                  </Typography>
                  <ArrowForwardIcon />
                </Button>
              </Box>
            </Box>
            <Box>
              {resp.text ? (
                <>
                  <Divider />
                  <Collapse in={openViewMore === key} timeout="auto" sx={{ mt: 2 }}>
                    {resp.text}
                  </Collapse>
                  <Box onClick={() => handleClick(key)} mt={2} sx={{ width: 'max-content' }}>
                    {openViewMore === key ? (
                      <ButtonMoreLess sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Box>
                          <Typography variant="body2B">Ver menos detalhes</Typography>
                        </Box>
                        <Box sx={{ height: '24px' }}>
                          <ExpandLessOutlinedIcon />
                        </Box>
                      </ButtonMoreLess>
                    ) : (
                      <ButtonMoreLess sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Box>
                          <Typography variant="body2B">Ver mais detalhes</Typography>
                        </Box>
                        <Box sx={{ height: '24px' }}>
                          <ExpandMoreOutlinedIcon />
                        </Box>
                      </ButtonMoreLess>
                    )}
                  </Box>
                </>
              ) : null}
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' }, mt: 3 }}>
              <Button
                variant="contained"
                sx={{ padding: '12px 16px 14px 24px', justifyContent: 'space-between' }}
                fullWidth
                onClick={() => {
                  customEventTracking.send({ event: resp.name });
                  dispatch(
                    saveCalendarDetails({
                      id: product.calendar.id,
                      calendarType: product.calendar.calendarType,
                      durationEvent: product.calendar.durationEvent,
                      userId: resp.userId
                    })
                  );
                  navegate(`/servico/${product.id}/especialista/${resp.userId}`);
                }}
              >
                <Typography variant="body1B" sx={{ mr: 1.5 }}>
                  Selecionar
                </Typography>
                <ArrowForwardIcon />
              </Button>
            </Box>
          </Wrapper>
        );
      })}
    </>
  );
};

export default CardDoctor;

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../images/logo_horizontal.svg';
import MenuMobile from './MenuMobile';
import Button from '@mui/material/Button';
import { eventTracking } from './../../../lib/gtm';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { IconButton } from './styled';

const ResponsiveAppBar = () => {
  return (
    <AppBar position="static" sx={{ p: 0, backgroundColor: 'transparent', boxShadow: 0 }}>
      <Toolbar disableGutters sx={{ minHeight: { md: '56px' } }}>
        <Box sx={{ flexGrow: 1, cursor: 'pointer' }}>
          <Link to="https://www.oya.care/" target="_self" rel="noreferrer">
            <Logo data-testid="home-appbar-logo" />
          </Link>
        </Box>
        <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
          <Button
            data-testid="home-appbar-button-contact"
            variant="contained"
            sx={{ py: 1, px: { md: 2 } }}
            href="https://bit.ly/Fale_com_a_Oya"
            target="_blank"
            endIcon={<WhatsAppIcon data-testid="home-appbar-button-icon-contact" />}
            onClick={() => {
              eventTracking.click({
                category: 'Agendamento',
                label: ' Entrar em contato - Menu'
              });
            }}
          >
            Entrar em contato
          </Button>
        </Box>

        <Box sx={{ flexGrow: 0, mr: 2, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            data-testid="icon-button"
            onClick={() => {
              window.open('https://bit.ly/Fale_com_a_Oya');
              eventTracking.click({
                category: 'WhatsApp',
                label: 'Fale com a gente - Flutuante'
              });
            }}
          >
            <WhatsAppIcon />
          </IconButton>
        </Box>
        <MenuMobile />
      </Toolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;

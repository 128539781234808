import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetAvailableTimesMutation } from '../../../../services/availableTimes.service';
import { RootState } from '../../../../store';
import {
  converToLocalTimeZone,
  filterByDay,
  getAllAvailableDaysOfTheMonth,
  getMonth,
  getNextMonth,
  getShortedAvailableDays,
  getYear,
  groupScheduleAvailableTimes,
  GroupTimes,
  isLastDaysOfMonth
} from '../../../../lib/dayjs';

export interface IAppointments {
  availableList: string[];
}

export const useAppointmentsAvailable = () => {
  const {
    product: { calendar }
  } = useSelector((state: RootState) => state);
  const [appointments, setAppointments] = useState<IAppointments>({ availableList: [] });
  const [currentMonth, setCurrentMonth] = useState<string>(getMonth() || '');
  const [currentYear, setCurrentYear] = useState<string>(getYear() || '');
  const [getAvailableTimes, { data, isLoading }] = useGetAvailableTimesMutation();
  let retryNextMoth = true;

  useEffect(() => {
    if (calendar.id !== undefined && calendar.id.length < 1) return;
    getAvailableTimes({ calendar, month: currentMonth, year: currentYear });
  }, [calendar.id, currentMonth, currentYear]);

  useEffect(() => {
    const nextMonth = getNextMonth();
    if (data !== undefined) {
      setAppointments(converToLocalTimeZone({ availableList: [...new Set([...appointments.availableList, ...data])] }));
      if (isLastDaysOfMonth() && getMonth() === currentMonth && retryNextMoth === true) {
        retryNextMoth = false;
        setCurrentMonth(nextMonth);
      }
    }
  }, [data, currentMonth, currentYear]);

  return { appointments, setCurrentMonth, setCurrentYear, isLoading };
};

export const useShortedAvailableDays = (appointmentsAvailable: IAppointments) => {
  const [shortedAvailableDays, setShortedAvailableDays] = useState<string[]>([]);
  useEffect(() => {
    setShortedAvailableDays(getShortedAvailableDays(appointmentsAvailable));
  }, [appointmentsAvailable?.availableList]);

  return [shortedAvailableDays] as const;
};

export const useAllAvailableDaysOfTheMonth = (appointmentsAvailable: IAppointments) => {
  const [allAvailableDaysOfTheMonth, setAllAvailableDaysOfTheMonth] = useState<string[]>([]);
  useEffect(() => {
    setAllAvailableDaysOfTheMonth(getAllAvailableDaysOfTheMonth(appointmentsAvailable));
  }, [appointmentsAvailable?.availableList]);

  return [allAvailableDaysOfTheMonth] as const;
};

export const useScheduleAvailableTimeGroupedByPeriod = (appointments: IAppointments, day: string) => {
  const [scheduleAvailableTimeGroupedByPeriod, setScheduleAvailableTimeGroupedByPeriod] = useState<GroupTimes>({
    morning: { availableList: [] },
    afternoon: { availableList: [] },
    evening: { availableList: [] }
  });

  useEffect(() => {
    setScheduleAvailableTimeGroupedByPeriod(groupScheduleAvailableTimes(filterByDay(day, appointments)));
  }, [appointments, day]);

  return [scheduleAvailableTimeGroupedByPeriod];
};

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

type LiStyledProps = {
  isOyaService: boolean;
};

export const Price = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutrals.beige1,
  marginBottom: '10px',
  display: 'flex',
  flexDirection: 'column',
  '.value': {
    fontWeight: '700'
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
}));

export const Services = styled('ul')(({ theme }) => ({
  margin: 0,
  marginBottom: '8px',
  padding: 0,
  li: {
    display: 'inline-block',
    color: theme.palette.neutrals.beige1
  },
  '.separator': {
    margin: '0 10px'
  }
}));

export const Refund = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: theme.palette.brand.lightGreen,
  svg: {
    marginLeft: '7px'
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '7px'
  }
}));

export const ServicesDetails = styled('ul')(() => ({
  position: 'relative',
  margin: 0,
  padding: 0,
  listStyleType: 'none',
  display: 'block'
}));
export const ButtonViewMore = styled(Box)(({ theme }) => ({
  width: 'max-content',
  color: theme.palette.neutrals.beige1,
  ':hover': {
    color: '#ffffffc7'
  }
}));

export const LiStyled = styled('li')<LiStyledProps>(({ theme, isOyaService }) => ({
  paddingBottom: '33px',
  position: 'relative',
  paddingLeft: '51px',
  color: theme.palette.neutrals.beige1,

  [theme.breakpoints.down('md')]: {
    paddingBottom: '24px'
  },

  '::before': {
    color: isOyaService ? 'initial' : theme.palette.neutrals.beige1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    content: 'counter(list-item)',
    width: '34px',
    height: '34px',
    borderRadius: '100%',
    textAlign: 'center',
    backgroundColor: isOyaService ? theme.palette.neutrals.beige1 : theme.palette.brand.darkGreen,
    border: !isOyaService ? `1px dashed ${theme.palette.neutrals.beige4}` : '',
    marginRight: '10px',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1
  },
  '::after': {
    display: 'inline-block',
    content: '""',
    width: '1px',
    height: '100%',
    backgroundColor: theme.palette.neutrals.beige4,
    left: '17px',
    position: 'absolute',
    top: '0',
    zIndex: 0
  },
  ':last-child::after': {
    content: 'none'
  }
}));

export const ScheduleSelectedDate = styled(Typography)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: theme.palette.colored.green2,
  borderRadius: '4px',
  color: theme.palette.neutrals.beige1,
  padding: '5px 8px 5px 4px',
  'span::first-letter': {
    textTransform: 'capitalize'
  },
  svg: {
    marginRight: '6px',
    width: '16px',
    height: '16px',
    path: {
      fill: theme.palette.neutrals.beige1
    }
  }
}));

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

window.dataLayer = window.dataLayer || [];

export const pageview = (url: string) => {
  window.dataLayer.push({
    event: 'page_view',
    page: url
  });
};

interface EventProps {
  action: string;
  category: string;
  label: string;
  value?: string;
}
export const event = ({ action, category, label, value }: EventProps) => {
  window.dataLayer.push({
    event: 'eventTracking',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value
  });
};

interface EventTrackingClickProps {
  category: string;
  label: string;
}
export const eventTracking = {
  click: ({ category, label }: EventTrackingClickProps) => {
    event({
      action: 'Clique',
      category,
      label
    });
  }
};

interface CusTomEventTrackingClickProps {
  event: string;
  props?: Record<string, any>;
}

export const customEventTracking = {
  send: ({ event, props }: CusTomEventTrackingClickProps) => {
    window.dataLayer.push({
      event: event,
      ...props
    });
  }
};

interface PurchaseEventTracking {
  paymentId: string;
  coupon: string;
  productId: string;
  productName: string;
  productPrice: number;
}

export const purchaseEventTracking = {
  send: (data: PurchaseEventTracking) => {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: data.paymentId,
        coupon: data.coupon,
        currency: 'BRL',
        value: data.productPrice,
        items: [
          {
            item_id: data.productId,
            item_name: data.productName,
            price: data.productPrice,
            quantity: 1
          }
        ]
      }
    });
  }
};

interface DynamicCusTomEventTrackingClickProps {
  dynamicEventName: string;
}

export const dynamicCustomEventTracking = {
  send: ({ dynamicEventName }: DynamicCusTomEventTrackingClickProps) => {
    window.dataLayer.push({
      event: 'dynamicEventTracking',
      dynamicEventName
    });
  }
};

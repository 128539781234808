import { createSlice } from '@reduxjs/toolkit';

interface ManagerStep {
  schedule: boolean; // se o usuário selecionou um horário
  personalData: boolean; // se o usuário preencheu os dados pessoais
  paymentMethod: boolean; // se o usuário selecionou um método de pagamento
  paymentPix: boolean; // se o usuário preencheu os dados do pagamento
  paymentCreditCard: boolean; // se o usuário preencheu os dados do cartão
  paymentMedicalInsurance: boolean; // se o usuário preencheu os dados do convênio médico
  confirmation: boolean; // se o usuário confirmou a compra
}
interface stepperState {
  clickNextStep: number;
  url: string;
  currentStep:
    | 'schedule'
    | 'personalData'
    | 'paymentMethod'
    | 'paymentCreditCard'
    | 'paymentPix'
    | 'paymentMedicalInsurance'
    | 'confirmation';
  validSteps: ManagerStep;
  isLoading: boolean;
  enabledBackButtonOnSchedulePage: boolean;
  validForm: {
    personalData: {
      valid: boolean;
    };
    paymentPix: {
      valid: boolean;
    };
    paymentCreditCard: {
      valid: boolean;
    };
    paymentMedicalInsurance: {
      valid: boolean;
    };
    paymentFree: {
      valid: boolean;
    };
  };
}

const initialState: stepperState = {
  clickNextStep: 0,
  url: '',
  currentStep: 'schedule',
  validSteps: {
    schedule: false,
    personalData: true,
    paymentMethod: false,
    paymentPix: false,
    paymentCreditCard: false,
    paymentMedicalInsurance: false,
    confirmation: false
  },
  isLoading: false,
  enabledBackButtonOnSchedulePage: false,
  validForm: {
    personalData: {
      valid: false
    },
    paymentCreditCard: {
      valid: false
    },
    paymentPix: {
      valid: false
    },
    paymentMedicalInsurance: {
      valid: false
    },
    paymentFree: {
      valid: false
    }
  }
};

// com o toolkit essa função substitui o reducer
export const nextPageSlice = createSlice({
  name: 'stepper',
  initialState,
  reducers: {
    goTo: (state, action) => {
      state.url = action.payload;
    },
    // Essa função habilita o botão
    updateValidStep: (state, action) => {
      state.validSteps = { ...state.validSteps, ...action.payload };
    },
    // Essa função efetua o submite do formulario
    updateValidForm: (state, action) => {
      state.validForm = { ...state.validForm, ...action.payload };
    },
    // Essa função seta o estado de loading
    updateLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    saveCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    clickNextStep: state => {
      state.clickNextStep += 1;
    },
    enabledBackButtonOnSchedulePage: (state, action) => {
      state.enabledBackButtonOnSchedulePage = action.payload;
    }
  }
});

export const {
  goTo,
  updateValidStep,
  updateValidForm,
  saveCurrentStep,
  updateLoading,
  clickNextStep,
  enabledBackButtonOnSchedulePage
} = nextPageSlice.actions;
export const nextPageReducer = nextPageSlice.reducer;

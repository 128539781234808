import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FC, useEffect } from 'react';
import { ViewContent, ViewContentCenter, ViewRoot, ViewSidebar } from '../../layout/Main/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { enabledBackButtonOnSchedulePage, saveCurrentStep } from '../../features/stepper/stepper-slice';
import { useDispatch } from 'react-redux';
import CardChoiceService from '../../components/molecules/CardChoiceService';

export const ChooseService: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(saveCurrentStep('chooseService'));
    dispatch(enabledBackButtonOnSchedulePage(true));
  }, []);

  return (
    <>
      <ViewRoot>
        <ViewContent className="ViewContent">
          <ViewContentCenter>
            <Box mb={1} sx={{ maxWidth: '800px' }}>
              <CardChoiceService />
            </Box>

            <Box mb={5} mt={3}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ padding: '12px 24px 14px 16px' }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
                <Typography variant="body1" sx={{ ml: 1.5 }}>
                  Voltar
                </Typography>
              </Button>
            </Box>
          </ViewContentCenter>
        </ViewContent>
        <ViewSidebar withimage="true" component="aside" className="ViewSidebar">
          <Typography color="neutrals.beige1" variant="h1">
            Melhores opções para você
          </Typography>
        </ViewSidebar>
      </ViewRoot>
    </>
  );
};

export default ChooseService;

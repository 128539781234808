import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Oyana {
  id: string;
}
interface UserState {
  jorneyId: string;
  oyana?: Oyana;
}

const initialState: UserState = {
  jorneyId: '',
  oyana: { id: '' }
};

// com o toolkit essa função substitui o reducer
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUserInfo: (state, action: PayloadAction<UserState>) => {
      return (state = { ...initialState, ...action.payload });
    }
  }
});

export const { saveUserInfo } = userSlice.actions;
export const userReducer = userSlice.reducer;

import Modal  from '../../Modal'
import Typography from '@mui/material/Typography';

interface RefundProps {
  open: boolean;
  close: () => void;
}

const ModalRefund = ({ open, close }: RefundProps) => {
  return (
    <Modal
      open={open}
      onCloseHandler={close}
      title="O reembolso"
    >
      <Typography variant='body1'>Depois da realização do serviço, você receberá por e-mail um link com a nota fiscal para solicitar reembolso ao seu plano de saúde. Aí é só enviar a nota junto do seu relatório médico, geralmente no portal ou app do convênio. Simples assim!</Typography>
    </Modal>

  )
};

export default ModalRefund;

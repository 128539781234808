import Typography from '@mui/material/Typography';
import { ViewRoot, ViewContent, ViewContentCenter, ViewSidebar } from '../../layout/Main/styled';
import Stepper from '../../components/molecules/Stepper';
import ShoppingCart from '../../components/molecules/ShoppingCart';
import Schedules from '../../components/molecules/Schedules';
import { WrapperStepper } from './styled';
import { ECalendarType, IProductResult, useGetProductDetailsMutation } from '../../services/product.service';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveCalendarDetails, saveProductDetail } from '../../features/product/product-slice';
import ManagerStepper from '../../components/molecules/ManagerStepper';
import { saveCurrentStep } from '../../features/stepper/stepper-slice';
import { saveTotalToPay } from '../../features/payment/payment-slice';
import { RootState } from '../../store';
import AlertMessage from '../../components/atoms/AlertMessage';
import { orderServiceSteps } from '../../utils';

const Schedule = () => {
  const { productId, calendarIdURL, userId } = useParams();
  const { product } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [getProductDetails, { data, isLoading }] = useGetProductDetailsMutation();
  const findCalendarId = (product: IProductResult, calendarIdByURL: string): string => {
    const typeMain = product.calendarTypes.find(item => item.type === ECalendarType.MAIN);
    const typeBoth = product.calendarTypes.find(item => item.type === ECalendarType.BOTH);

    if (calendarIdByURL) {
      return calendarIdByURL;
    } else if (typeMain) {
      return typeMain.calendarId;
    } else if (typeBoth) {
      return typeBoth.calendarId;
    } else {
      return product.calendars[0].id;
    }
  };

  useEffect(() => {
    if (product.id.length <= 0 || productId !== product.id) {
      getProductDetails({ id: productId, doctorId: userId });
    }

    dispatch(saveCurrentStep('schedule'));
  }, []);

  useEffect(() => {
    if (data) {
      if (product.id.length <= 0 || productId !== product.id) {
        dispatch(
          saveProductDetail({
            ...product,
            id: data.id,
            services: data.services,
            servicesSteps: orderServiceSteps(data.servicesSteps),
            price: data.price,
            isNeedAddress: data.isNeedAddress,
            installment: data.maxInstallment,
            urlAnamnese: data.urlAnamnese,
            sendAnamnese: data.sendAnamnese,
            name: data.name,
            alias: data.alias,
            healthPlan: data.healthPlan,
            durationTime: data.durationTime,
            doctorName: data.doctorName
          })
        );
        dispatch(saveTotalToPay(data.price));
        const calendarID: string = findCalendarId(data, calendarIdURL || '');
        const calendarCurrent = data.calendars.find(item => {
          if (item.id === calendarID) return item;
        });
        dispatch(
          saveCalendarDetails({
            id: calendarCurrent?.id,
            calendarType: calendarCurrent?.calendarType,
            durationEvent: calendarCurrent?.durationEvent,
            userId
          })
        );
      }
    }
  }, [data]);

  const formatLabelCalendarType = (type: string) => {
    switch (type) {
      case 'EXAM':
        return 'seu exame';
      case 'VIRTUAL_CONSULTATION':
        return 'sua consulta online';
      case 'PHYSICAL_APPOINTMENT':
        return 'sua consulta';
      case 'PROCEDURE':
        return 'seu procedimento';
    }
  };

  return (
    <>
      <ViewRoot>
        <ViewContent className="ViewContent">
          <ViewContentCenter>
            <WrapperStepper>
              <Stepper separator="de" text="Escolha de data e hora" />
            </WrapperStepper>
            <Typography variant="h3">
              Escolha a melhor data para {formatLabelCalendarType(product.calendar.calendarType)}
            </Typography>
            {isLoading ? <p>Carregando...</p> : <Schedules />}
            <ManagerStepper />
            {!isLoading && !product.isServiceAvailableCityFromZipCode && (
              <AlertMessage
                noTimer
                oneOpen="true"
                icon={false}
                text={
                  <>
                    A coleta da Oya ainda não está disponível em sua região. Para realizar a consulta, você precisará ter o{' '}
                    <br />
                    resultado do exame AMH em mãos realizado no laboratório de sua preferência.
                  </>
                }
                textButton="Ok, entendi"
                type="success"
              />
            )}
          </ViewContentCenter>
        </ViewContent>
        <ViewSidebar component="aside" className="ViewSidebar">
          {isLoading ? <p>Carregando...</p> : <ShoppingCart />}
        </ViewSidebar>
      </ViewRoot>
    </>
  );
};

export default Schedule;

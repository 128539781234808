import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Container, { ContainerProps } from '@mui/material/Container';

export const WrapperRoot = styled(Box)(() => ({
  backgroundColor: 'blue'
}));

export const WrapperContainerGrid = styled(Grid)(() => ({
  margin: 'auto'
}));

export const WrapperContainerGridVariant = styled(Grid)(({ theme }) => ({
  margin: 'auto',
  backgroundColor: theme.palette.neutrals.beige2
}));

export const ContainerCustomGutters = styled((props: ContainerProps) => <Container disableGutters {...props} />)(
  ({ theme }) => ({
    padding: '0 80px',
    [theme.breakpoints.down('md')]: {
      padding: '0 40px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px'
    }
  })
);

export const BoxdWithSpacing = styled(Box)(({ theme }) => ({
  padding: '80px 0',
  [theme.breakpoints.down('md')]: {
    padding: '56px 0'
  }
}));

export const BoxdWithSpacingInternalPage = styled(Box)(() => ({
  marginTop: '56px',
  paddingTop: '40px'
}));

import { FC } from 'react';
import { Subtitle } from './styled';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

interface KnowMoreInsideModal {
  title: string;
  subtitle?: string;
  primaryButton?: {
    href: string;
    label: string;
    icon?: React.ReactNode;
    target?: string;
  };
  secundaryButton?: {
    href: string;
    label: string;
    icon?: React.ReactNode;
    target?: string;
  };
}

const KnowMoreInsideModal: FC<KnowMoreInsideModal> = ({ title, subtitle, primaryButton, secundaryButton }) => {
  return (
    <>
      <Typography variant="h3" sx={{ marginBottom: '8px' }}>
        {title}
      </Typography>

      <Subtitle variant="body1">{subtitle}</Subtitle>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'flex-start', gap: 2, pt: 2 }}>
        {primaryButton && (
          <Button
            href={primaryButton.href}
            variant="contained"
            color="primary"
            target={primaryButton.target || '_self'}
            endIcon={primaryButton.icon}
            sx={{ marginRight: '16px' }}
          >
            {primaryButton.label}
          </Button>
        )}
        {secundaryButton && (
          <Button
            href={secundaryButton.href}
            variant="contained"
            color="secondary"
            endIcon={secundaryButton.icon}
            target={secundaryButton.target || '_self'}
          >
            {secundaryButton.label}
          </Button>
        )}
      </Box>
    </>
  );
};

export default KnowMoreInsideModal;

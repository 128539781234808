import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Tag } from './styled';

interface Props {
  tags: string;
  setValueModal?: any;
}

export const TagsWithFunction: FC<Props> = ({ tags, setValueModal }) => {
  const tagsFormated = tags.split(';');
  return (
    <>
      <Box>
        <Box sx={{ display: 'flex', mb: 1 }}>
          {tagsFormated && (
            <Box sx={{ mr: 1, display: 'flex' }}>
              {tagsFormated.map(res => {
                return (
                  <Tag key={res + 'tags'} mr={1} onClick={() => setValueModal(res)}>
                    <Typography variant="body2R">{res}</Typography>
                  </Tag>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TagsWithFunction;

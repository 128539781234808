import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { IAppointments } from '../../components/molecules/Schedules/hooks';
require('dayjs/locale/pt-br');
dayjs.extend(isTomorrow);
dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('pt-br');

export interface GroupTimes {
  [key: string]: IAppointments;
}

export const converToLocalTimeZone = (appointments: IAppointments): IAppointments => {
  const localTimeAppointments: IAppointments = { availableList: [] };
  appointments.availableList.forEach(time => {
    localTimeAppointments.availableList.push(dayjs(time).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss'));
  });
  return localTimeAppointments;
};

export const getAllAvailableDaysOfTheMonth = (appointments: IAppointments): string[] => {
  const grouped: string[] = [];
  appointments.availableList.forEach(value => {
    const time = dayjs.tz(value).locale('pt-br');
    const currentDate = time.format('YYYY-M-D');
    if (!grouped.includes(currentDate)) {
      grouped.push(currentDate);
    }
  });
  return grouped;
};

export const getShortedAvailableDays = (appointments: IAppointments): string[] => {
  const grouped: string[] = [];
  const getFistTwoDays = (groupedSchedules: string[]): string[] => {
    return groupedSchedules.slice(0, 2);
  };
  appointments.availableList.forEach(value => {
    const time = dayjs.tz(value).locale('pt-br');
    const currentDate = time.format('YYYY-M-D');
    if (!grouped.includes(currentDate)) {
      grouped.push(currentDate);
    }
  });
  return getFistTwoDays(grouped);
};

export const getNextMonth = (time: Dayjs = dayjs()): string => {
  return (time.get('month') + 1).toString();
};

export const getMonth = (time: Dayjs = dayjs()): string => {
  return time.get('month').toString();
};

export const getYear = (time: Dayjs = dayjs()): string => {
  return time.get('year').toString();
};

export const isLastDaysOfMonth = (time: Dayjs = dayjs()): boolean => {
  const d4 = 6; // last day of the month
  const today = time.get('date');
  const totalMonthDays = time.daysInMonth() - d4;
  return today >= totalMonthDays;
};

export const isMorning = (time: string) => dayjs.tz(time).get('hour') >= 0 && dayjs.tz(time).get('hour') <= 11;
export const isAfternoon = (time: string) => dayjs.tz(time).get('hour') >= 12 && dayjs.tz(time).get('hour') <= 17;
export const isEvening = (time: string) => dayjs.tz(time).get('hour') >= 18 && dayjs.tz(time).get('hour') <= 23;

export const groupScheduleAvailableTimes = (times: IAppointments): GroupTimes => {
  const group: GroupTimes = {
    morning: { availableList: [] },
    afternoon: { availableList: [] },
    evening: { availableList: [] }
  };
  times.availableList.forEach(time => {
    if (isMorning(time)) group.morning.availableList.push(time);
    if (isAfternoon(time)) group.afternoon.availableList.push(time);
    if (isEvening(time)) group.evening.availableList.push(time);
  });
  return group;
};

export const filterByDay = (day: string, times: IAppointments): IAppointments => {
  const filtered: IAppointments = { availableList: [] };
  filtered.availableList = times.availableList.filter(time => dayjs.tz(time).format('YYYY-M-D') === day);
  return filtered;
};

export const hasScheduleAvailableTimes = (group: GroupTimes): boolean => {
  if (
    group?.morning?.availableList.length > 0 ||
    group?.afternoon?.availableList.length > 0 ||
    group?.evening?.availableList.length > 0
  )
    return true;
  return false;
};

export const hasAvailableTimesToCurrentMonth = (availableDays: string[], currentMonth: string): boolean => {
  const filtered = availableDays.filter(time => dayjs(time).get('month').toString() === currentMonth);
  return filtered.length > 0;
};

export const formatShortedAvailableDays = (currentDay: string): string => {
  const current = dayjs(currentDay);
  if (current.isToday()) return 'Hoje';
  if (current.isTomorrow()) return 'Amanhã';
  return current.format('dddd, D [de] MMMM');
};

export const formatAvailableDaysByDatePicker = (currentDay: string): string => {
  const current = dayjs(currentDay);
  return current.format('dddd, D [de] MMMM');
};

export const formatPeriodLabel = (period: string): string => {
  switch (period) {
    case 'morning':
      return 'manhã';
    case 'afternoon':
      return 'tarde';
    case 'evening':
      return 'noite';
    default:
      return '';
  }
};

export const formatSelectedTime = (time: string): string => {
  return dayjs.tz(time).format('dddd, D [de] MMMM [às] HH:mm');
};

export const formatTimeLabel = (time: string): string => {
  return dayjs.tz(time).format('HH:mm');
};

export const formatConfirmTimeLabel = (time: string): string => {
  if (time === '') return '';
  return dayjs.tz(time).format('DD/MM/YYYY [às] HH:mm');
};

export const converToLocalTimeZoneOneDate = (time: string): string => {
  return dayjs(time).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss');
};

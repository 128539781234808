import { Suspense, useEffect } from 'react';
import { Routes, Route, useLocation, useSearchParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Main from './Main';
import AppBar from '../components/organisms/AppBar';
import PageLoading from '../components/molecules/Loading';
import Schedule from '../pages/Schedule';
import PaymentConfirmation from '../pages/PaymentConfirmation';
import { customEventTracking, pageview } from '../lib/gtm';
import ServiceAvailability from '../pages/ServiceAvailability';
import ServiceAvailabilityByZIPCode from '../pages/ServiceAvailabilityByZIPCode';
import ChooseAppointmentType from '../pages/ChooseAppointmentType';
import ChooseDoctor from '../pages/ChooseDoctor';
import { useDispatch } from 'react-redux';
import { PaymentFlowType } from '../Interfaces/PaymentFlowType.interface';
import { saveQueryString } from '../features/queryString/queryString-slice';
import NeedList from '../pages/NeedList';
import ChooseService from '../pages/ChooseService';
import PaymentAlreadyDone from '../pages/PaymentAlreadyDone';
import { lazyWithRetry } from '../utils/lazyWithRetry';

const PersonalData = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PersonalData"*/
      /* webpackPrefetch: true */
      '../pages/PersonalData'
    ),
  'PersonalData'
);
const PaymentMethod = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PaymentMethod"*/
      /* webpackPrefetch: true */
      '../pages/PaymentMethod'
    ),
  'PaymentMethod'
);
const PaymentCreditCard = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PaymentCreditCard"*/
      /* webpackPrefetch: true */
      '../pages/PaymentCreditCard'
    ),
  'PaymentCreditCard'
);
const PaymentPixPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PaymentPix"*/
      /* webpackPrefetch: true */
      '../pages/PaymentPix'
    ),
  'PaymentPixPage'
);

const PaymentMedicalInsurance = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PaymentMedicalInsurance"*/
      /* webpackPrefetch: true */
      '../pages/PaymentMedicalInsurance'
    ),
  'PaymentMedicalInsurance'
);

export const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const getQueryString = {
    flowType: searchParams.get('flow-type') || PaymentFlowType.DEFAULT,
    journeyid: searchParams.get('journeyid') || '',
    productid: searchParams.get('productid') || '',
    additionalserviceid: searchParams.get('additionalserviceid') || '',
    coupon: searchParams.get('coupon') || '',
    returnurl: searchParams.get('returnurl') || '',
    utms: {
      utm_campaign: searchParams.get('utm_campaign') || '',
      utm_medium: searchParams.get('utm_medium') || '',
      utm_source: searchParams.get('utm_source') || ''
    }
  };

  useEffect(() => {
    // Google Analytics custom events
    customEventTracking.send({ event: 'journey_init' });
    dispatch(saveQueryString(getQueryString));
  }, []);

  useEffect(() => {
    // Google Analytics
    pageview(location.pathname);
    //Scroll to top
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Header>
        <AppBar />
      </Header>
      <Main>
        <Routes>
          <Route index path="/" element={<NeedList />} />
          <Route path="/selecao-de-servicos" element={<NeedList />} />
          <Route path="/servico/:productId/:calendarIdURL?" element={<Schedule />} />
          <Route path="/selecao-de-servicos/:serviceId" element={<ChooseService />} />
          <Route path="/servico/:productId/especialista/:userId?" element={<Schedule />} />
          <Route
            path="/servico/:productId/disponibilidade"
            element={
              <Suspense fallback={<div>Carregando...</div>}>
                <ServiceAvailability />
              </Suspense>
            }
          />
          <Route
            path="/servico/:productId/disponibilidade/cep"
            element={
              <Suspense fallback={<div>Carregando...</div>}>
                <ServiceAvailabilityByZIPCode />
              </Suspense>
            }
          />
          <Route
            path="/dados-pessoais"
            element={
              <Suspense fallback={<PageLoading />}>
                <PersonalData />
              </Suspense>
            }
          />
          <Route
            path="/opcoes-de-pagamento/:journeyId?/:productId?"
            element={
              <Suspense fallback={<PageLoading />}>
                <PaymentMethod />
              </Suspense>
            }
          />
          <Route
            path="/pagamento-cartao"
            element={
              <Suspense fallback={<PageLoading />}>
                <PaymentCreditCard />
              </Suspense>
            }
          />
          <Route
            path="/pagamento-pix"
            element={
              <Suspense fallback={<PageLoading />}>
                <PaymentPixPage />
              </Suspense>
            }
          />
          <Route
            path="/pagamento-convenio-medico"
            element={
              <Suspense fallback={<PageLoading />}>
                <PaymentMedicalInsurance />
              </Suspense>
            }
          />
          <Route
            path="/confirmacao-pagamento"
            element={
              <Suspense fallback={<div>Carregando...</div>}>
                <PaymentConfirmation />
              </Suspense>
            }
          />
          <Route
            path="/pagamento-efetuado"
            element={
              <Suspense fallback={<div>Carregando...</div>}>
                <PaymentAlreadyDone />
              </Suspense>
            }
          />
          <Route
            path="/servico/como-agendar/:productId"
            element={
              <Suspense fallback={<div>Carregando...</div>}>
                <ChooseAppointmentType />
              </Suspense>
            }
          />
          <Route
            path="/servico/escolha-medica"
            element={
              <Suspense fallback={<div>Carregando...</div>}>
                <ChooseDoctor />
              </Suspense>
            }
          />
        </Routes>
      </Main>
      <Footer />
    </>
  );
};

export default App;

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ViewContentCenter } from '../../../layout/Main/styled';
import {
  PaperCard,
  PaperCardContent,
  PaperCardFooterDetail,
  Wrapper,
  WrapperFooter,
  WrapperContent,
  PaperCardFooterImage,
  WrapperContentTwo
} from './style';
import Button from '@mui/material/Button';
import CircleIcon from '../../atoms/CircleIcon';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import SessionLimiterStyled from '../../atoms/SessionLimiter/styled';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { formatCurrency } from '../../../validations/utils';
import seta from '../../../images/triangle.png';
import { useEffect, useState } from 'react';
import Modal from './Modal';
import { PaymentFlowType } from '../../../Interfaces/PaymentFlowType.interface';
import { formatConfirmTimeLabel } from '../../../lib/dayjs';
import KnowPlataform from '../KnowPlataform';
import { CardMedia } from '../CardMedia/style';

const ContentPaymentConfirmation = () => {
  const {
    schedule,
    paymentDetail,
    product,
    user,
    queryString: { flowType }
  } = useSelector((state: RootState) => state);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState('#');
  const handleOpen = () => setOpen(!open);
  const hasAnamnese = product.sendAnamnese && product.urlAnamnese.length > 0;

  useEffect(() => {
    const urlNewAnamnese = product.urlAnamnese.match(/home-anamnese/);
    if (urlNewAnamnese && urlNewAnamnese[0] === 'home-anamnese') {
      setUrl(`${product.urlAnamnese}/${user.jorneyId}/${user.oyana?.id}`);
    } else setUrl(`${product.urlAnamnese}#journeyid=${user.jorneyId}&type=anamnese`);
  }, [product.urlAnamnese, user.jorneyId]);

  return (
    <>
      <Wrapper small={hasAnamnese.toString()}>
        {hasAnamnese && (
          <Grid container item sx={{ mb: 4 }}>
            <Grid xs={0} md={3}></Grid>
            <Grid xs={12} md={6} item>
              <Box sx={{ mt: 7, mb: 7 }}>
                <Box sx={{ mb: 2 }}>
                  <Box sx={{ mb: 2 }}>
                    <Typography color="neutrals.beige1" variant="h2">
                      Responda as perguntas médicas
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="neutrals.beige1" variant="body1R">
                      Para aproveitar melhor o tempo da sua consulta, responda as perguntas sobre a sua saúde
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Button variant="contained" size="medium" href={url}>
                    Responder agora
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid xs={0} md={3}></Grid>
          </Grid>
        )}
      </Wrapper>

      <WrapperContent>
        <Grid container item sx={{ mb: 4 }}>
          <Grid xs={0} md={3}></Grid>
          <Grid xs={12} md={6} item>
            <PaperCard>
              <PaperCardContent>
                <Box>
                  <Box sx={{ mb: 2 }}>
                    <CircleIcon icon={<CheckOutlinedIcon />} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h3">
                      {paymentDetail.method === 'medicalinsurance' ? 'Agendamento confirmado' : 'Pagamento realizado'}
                    </Typography>
                  </Box>
                  <SessionLimiterStyled />
                </Box>
                {flowType === PaymentFlowType.DEFAULT ? (
                  <>
                    <Box>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="body1B">Data do agendamento</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body1R">{formatConfirmTimeLabel(schedule.dateStart)}</Typography>
                        </Box>
                        <Box>
                          {
                            //juste próvisorio para o lançamento
                            product.calendar.calendarType === 'VIRTUAL_CONSULTATION' && (
                              <>
                                <Button
                                  sx={{ padding: '0px' }}
                                  variant="inline"
                                  onClick={() => {
                                    handleOpen();
                                  }}
                                >
                                  Como funciona
                                </Button>
                                <Modal
                                  openCloseController={open}
                                  onCloseHandler={() => {
                                    handleOpen();
                                  }}
                                />
                              </>
                            )
                          }
                        </Box>
                      </Box>
                    </Box>
                    <SessionLimiterStyled />

                    {paymentDetail.user.doctor && (
                      <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Box>
                            <Typography variant="body1B" sx={{ mb: 2 }}>
                              Quem vai antender
                            </Typography>
                            <Typography variant="body1R">{paymentDetail.user.name}</Typography>
                          </Box>
                          <CardMedia component="img" alt={paymentDetail.user.name} image={paymentDetail.user.profileImage} />
                        </Box>
                        <SessionLimiterStyled sx={{ mt: '24px' }} />
                      </>
                    )}
                  </>
                ) : (
                  []
                )}

                <Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="body1B">Pagamento</Typography>
                  </Box>
                  <Box>
                    <Box sx={{ mb: 1 }}>
                      {paymentDetail.method === 'creditCard' ? (
                        <Typography variant="body1R">Cartão de Crédito</Typography>
                      ) : null}
                      {paymentDetail.method === 'pix' ? <Typography variant="body1R">Pix</Typography> : null}
                      {paymentDetail.method === 'medicalinsurance' ? (
                        <>
                          <Typography variant="body1R" mb={1}>
                            {paymentDetail.healthPlan.insurance.healthPlan.name} {paymentDetail.healthPlan.insurance.name}
                          </Typography>
                          <Typography variant="body1R" mb={1}>
                            {paymentDetail.healthPlan.cardNumber}
                          </Typography>
                          <Typography variant="body1R" mb={1}>
                            {paymentDetail.healthPlan.cardExpirationDate}
                          </Typography>
                        </>
                      ) : null}
                      {paymentDetail.cupomName.length > 0 && (
                        <Typography variant="body1R">{`Cupom ${paymentDetail.cupomName}`} </Typography>
                      )}
                    </Box>
                    {paymentDetail.method === 'medicalinsurance' ? null : (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="body1R">
                          {formatCurrency(paymentDetail.amount, 'BRL')}
                          {paymentDetail.installments === 1 ? ' à vista' : ` ${paymentDetail.installments}x(sem juros)`}
                        </Typography>
                      </Box>
                    )}
                    {paymentDetail.amount > 0 && flowType === PaymentFlowType.DEFAULT && (
                      <Box>
                        <Typography variant="body1R">Você receberá a nota fiscal após a consulta por e-mail. </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>

                {paymentDetail.billingAddress.address ? (
                  <>
                    <SessionLimiterStyled />
                    <Box sx={{ mb: 8 }}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="body1B">Endereço de cobrança</Typography>
                      </Box>
                      <Box>
                        <Box sx={{ mb: 1 }}>
                          <Typography variant="body1R">
                            {`${paymentDetail.billingAddress.address}, ${paymentDetail.billingAddress.addressNumber}`}
                          </Typography>
                        </Box>
                        <Box sx={{ mb: 1 }}>
                          <Typography variant="body1R">
                            {`${paymentDetail.billingAddress.neighborhood}, ${paymentDetail.billingAddress.city} - ${paymentDetail.billingAddress.state}`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : (
                  ''
                )}
              </PaperCardContent>
              <PaperCardFooterDetail sx={{ mb: 7 }}>
                <PaperCardFooterImage sx={{ backgroundImage: `url(${seta})`, height: '16px' }}></PaperCardFooterImage>
              </PaperCardFooterDetail>
            </PaperCard>
          </Grid>
          <Grid xs={0} md={3}></Grid>
        </Grid>
      </WrapperContent>
      <WrapperContentTwo>
        <KnowPlataform />
      </WrapperContentTwo>
      <SessionLimiterStyled sx={{ marginTop: 0 }} />
      <WrapperFooter
        heightLess
        sx={{
          display: { xs: 'grid', md: 'flex' },
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ mb: { xs: 5, md: 0 } }}>
          <Typography variant="h3">Precisa de ajuda? </Typography>
        </Box>
        <Box sx={{ display: { xs: 'grid', md: 'flex' } }}>
          <Box sx={{ mb: { xs: 2, md: 0 } }}>
            <Button
              variant="contained"
              fullWidth
              endIcon={<WhatsAppIcon />}
              href="https://bit.ly/Fale_com_a_Oya"
              target="_blank"
            >
              Fale com a gente
            </Button>
          </Box>
          <Box sx={{ ml: { xs: 0, md: 2 }, mb: { xs: 5, md: 0 } }}>
            <Typography variant="h3">
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                endIcon={<ArrowForwardIcon />}
                href="https://www.oya.care/sobre/perguntas-frequentes?_ga=2.23775738.1589004085.1677176504-1625506865.1673358955"
                target="_blank"
              >
                Ver dúvidas frequentes
              </Button>
            </Typography>
          </Box>
        </Box>
      </WrapperFooter>
    </>
  );
};

export default ContentPaymentConfirmation;

import { FC } from 'react';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { CardStyled, Tag } from './styled';

interface ButtonNeedProps {
  title: string;
}

const ButtonNeed: FC<ButtonNeedProps> = ({ title }) => {
  return (
    <>
      <CardStyled>
        <Box sx={{ py: 1, px: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: 278, height: 40 }}>
            <Typography variant="body1R">{title}</Typography>
          </Box>
        </Box>
      </CardStyled>
    </>
  );
};

export default ButtonNeed;

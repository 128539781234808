import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { WrapperActions } from './styled';
import { RootState } from '../../../store';
import { clickNextStep, updateValidForm } from '../../../features/stepper/stepper-slice';
import { customEventTracking, dynamicCustomEventTracking } from '../../../lib/gtm';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

const ManagerStepper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [backButtonDisable, setBackButtonDisabled] = useState(false);
  const { nextPage, schedule, payment } = useSelector((state: RootState) => state);

  const toggleVisibilityBackButton = () => {
    if (nextPage.currentStep === 'schedule' && nextPage.enabledBackButtonOnSchedulePage === false) {
      return true;
    }
    return false;
  };

  const isValidStep = (): boolean => {
    const { currentStep, validSteps } = nextPage;
    if (validSteps[currentStep] === true) {
      return true;
    }
    return false;
  };

  const handleNextPage = () => {
    dispatch(clickNextStep());
    switch (nextPage.currentStep) {
      case 'schedule':
        navigate(nextPage.url);
        customEventTracking.send({
          event: 'journey_schedule_selected',
          props: {
            schedule: schedule.dateStart
          }
        });
        break;
      case 'personalData':
        dispatch(updateValidForm({ personalData: { valid: true } }));
        break;
      case 'paymentCreditCard':
        dispatch(updateValidForm({ paymentCreditCard: { valid: true } }));
        break;
      case 'paymentMedicalInsurance':
        dispatch(updateValidForm({ paymentMedicalInsurance: { valid: true } }));
        break;
      case 'paymentMethod':
        dynamicCustomEventTracking.send({ dynamicEventName: 'journey_payment_method_use_ag' });
        if (payment.method === 'free') {
          dispatch(updateValidForm({ paymentFree: { valid: true } }));
        } else navigate(nextPage.url);
        break;
      default:
        navigate(nextPage.url);
    }
  };

  useEffect(() => {
    if (nextPage.currentStep === 'paymentMethod') {
      setBackButtonDisabled(true);
    } else if (
      nextPage.currentStep === 'paymentCreditCard' ||
      nextPage.currentStep === 'paymentPix' ||
      nextPage.currentStep === 'paymentMedicalInsurance'
    ) {
      setBackButtonDisabled(false);
    }
  }, [nextPage.currentStep]);

  return (
    <WrapperActions>
      <Button
        variant="contained"
        color="secondary"
        disabled={backButtonDisable}
        sx={{ visibility: toggleVisibilityBackButton() ? 'hidden' : 'visible', padding: '12px 24px 14px 16px' }}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIcon />
        <Typography variant="body1" sx={{ ml: 1.5 }}>
          Voltar
        </Typography>
      </Button>

      <LoadingButton
        loading={nextPage.isLoading}
        variant="contained"
        onClick={handleNextPage}
        disabled={!isValidStep()}
        sx={{ padding: '12px 16px 14px 24px' }}
      >
        <Typography variant="body1" sx={{ mr: 1.5 }}>
          Continuar
        </Typography>
        <ArrowForwardIcon />
      </LoadingButton>
    </WrapperActions>
  );
};

export default ManagerStepper;

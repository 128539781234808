import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';

export const MenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutrals.beige1,
  '&:hover': {
    color: theme.palette.brand.lightGreen,
    backgroundColor: 'transparent'
  }
}));

export const LoginButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutrals.lead,
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent'
  },
  path: {
    fill: theme.palette.neutrals.lead
  }
}));

export const WrapperBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.beige1
}));

export const MenuListItem = styled(ListItemButton)(() => ({
  textDecoration: 'none',
  paddingTop: 19,
  paddingBottom: 19,
  paddingLeft: 24,
  paddingRight: 24
}));

export const WrapperSubMenu = styled(Collapse)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.beige2,
  marginLeft: 10,
  marginRight: 10
}));

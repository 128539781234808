import { useState } from 'react';

import Box from '@mui/material/Box';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';

import { ButtonMoreLess } from '../styled';
import { ReactComponent as IconCheckSuccess } from '../../../../images/icon_check_success.svg';

interface Props {
  title: string;
  items: string[];
}

const ListItems = ({ title, items }: Props) => {
  const [openViewMore, setOpenViewMore] = useState<boolean>(false);

  const handleClick = () => {
    setOpenViewMore(!openViewMore);
  };
  return (
    <>
      <Box onClick={() => handleClick()} mt={2} sx={{ width: 'max-content' }}>
        <ButtonMoreLess sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', mb: 2 }}>
          <Box>
            <Typography variant="body2B">{title}</Typography>
          </Box>
          <Box sx={{ height: '24px' }}>{openViewMore ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}</Box>
        </ButtonMoreLess>
      </Box>
      <Collapse in={openViewMore} timeout="auto">
        {items.map((value, key) => {
          return (
            <Box key={key} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Box>
                <IconCheckSuccess />
              </Box>
              <Box>
                <Typography ml={1} variant="body2R">
                  {value}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Collapse>
    </>
  );
};

export default ListItems;

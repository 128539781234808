import { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { StepperNumbers } from './styled';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';

interface Props {
  separator?: string;
  text: string;
  iconEnd?: any;
}

interface PathsI {
  [key: string]: {
    [key: string]: number;
  };
}

export const Stepper: FC<Props> = ({ separator, text, iconEnd }) => {
  const location = useLocation();
  const {
    product: { id, calendar }
  } = useSelector((state: RootState) => state);

  const [totalSteps, setTotalSteps] = useState<number>(0);
  const [currentJourney, setCurrentJourney] = useState<number>(1);

  const Paths: PathsI = {
    [process.env.REACT_APP_PRODUCT_SOS || '#']: {
      [`/servico/${process.env.REACT_APP_PRODUCT_SOS}`]: 1,
      '/dados-pessoais': 2,
      '/opcoes-de-pagamento': 3,
      '/pagamento-convenio-medico': 3,
      '/pagamento-cartao': 3,
      '/pagamento-pix': 3
    },
    [process.env.REACT_APP_PRODUCT_CUG || '#']: {
      [`/servico/${process.env.REACT_APP_PRODUCT_CUG}`]: 1,
      '/dados-pessoais': 2,
      '/opcoes-de-pagamento': 3,
      '/pagamento-convenio-medico': 3,
      '/pagamento-cartao': 3,
      '/pagamento-pix': 3
    },
    [process.env.REACT_APP_PRODUCT_CG || '#']: {
      [`/servico/${process.env.REACT_APP_PRODUCT_CG}`]: 1,
      '/dados-pessoais': 2,
      '/opcoes-de-pagamento': 3,
      '/pagamento-convenio-medico': 3,
      '/pagamento-cartao': 3,
      '/pagamento-pix': 3
    },
    [`${process.env.REACT_APP_PRODUCT_DF?.split('/')[0]}` || '#']: {
      [`/servico/${process.env.REACT_APP_PRODUCT_DF?.split('/')[0]}/${calendar.id}`]: 1,
      '/dados-pessoais': 2,
      '/opcoes-de-pagamento': 3,
      '/pagamento-convenio-medico': 3,
      '/pagamento-cartao': 3,
      '/pagamento-pix': 3
    },
    [process.env.REACT_APP_PRODUCT_CDO || '#']: {
      [`/servico/${process.env.REACT_APP_PRODUCT_CDO}`]: 1,
      '/dados-pessoais': 2,
      '/opcoes-de-pagamento': 3,
      '/pagamento-convenio-medico': 3,
      '/pagamento-cartao': 3,
      '/pagamento-pix': 3
    },
    [process.env.REACT_APP_PRODUCT_RA || '#']: {
      [`/servico/como-agendar/${process.env.REACT_APP_PRODUCT_RA}`]: 1,
      '/servico/escolha-medica': 1,
      [`/servico/${process.env.REACT_APP_PRODUCT_RA}/especialista/${calendar.userId}`]: 2,
      [`/servico/${process.env.REACT_APP_PRODUCT_RA}`]: 2,
      '/dados-pessoais': 3,
      '/opcoes-de-pagamento': 4,
      '/pagamento-convenio-medico': 4,
      '/pagamento-cartao': 4,
      '/pagamento-pix': 4
    },
    [process.env.REACT_APP_PRODUCT_JDC || '#']: {
      [`/servico/${process.env.REACT_APP_PRODUCT_JDC}`]: 1,
      '/dados-pessoais': 2,
      '/opcoes-de-pagamento': 3,
      '/pagamento-convenio-medico': 3,
      '/pagamento-cartao': 3,
      '/pagamento-pix': 3
    },
    [process.env.REACT_APP_PRODUCT_IDU || '#']: {
      [`/servico/${process.env.REACT_APP_PRODUCT_IDU}`]: 1,
      '/dados-pessoais': 2,
      '/opcoes-de-pagamento': 3,
      '/pagamento-convenio-medico': 3,
      '/pagamento-cartao': 3,
      '/pagamento-pix': 3
    },
    [process.env.REACT_APP_SCHEDULE_DF_WITHOUT_EXAM || '#']: {
      [`/servico/${process.env.REACT_APP_SCHEDULE_DF_WITHOUT_EXAM}`]: 1,
      '/dados-pessoais': 2,
      '/opcoes-de-pagamento': 3,
      '/pagamento-convenio-medico': 3,
      '/pagamento-cartao': 3,
      '/pagamento-pix': 3
    }
  };

  const steppersRouterDefault: PathsI = {
    [id || '#']: {
      [`/servico/${id}`]: 1,
      [`/servico/${id}/especialista/${calendar.userId}`]: 1,
      '/dados-pessoais': 2,
      '/opcoes-de-pagamento': 3,
      '/pagamento-convenio-medico': 3,
      '/pagamento-cartao': 3,
      '/pagamento-pix': 3
    }
  };

  const calculateTotalSteps = (Paths: PathsI) => {
    if (id === '') return 1;
    const totalSteps: number[] = [];
    if (Paths[id]) {
      Object.keys(Paths[id]).forEach(idProduct => {
        if (!totalSteps.includes(Paths[id][idProduct])) totalSteps.push(Paths[id][idProduct]);
      });
    } else
      Object.keys(steppersRouterDefault[id]).forEach(idProduct => {
        if (!totalSteps.includes(steppersRouterDefault[id][idProduct]))
          totalSteps.push(steppersRouterDefault[id][idProduct]);
      });

    return totalSteps.length;
  };

  useEffect(() => {
    if (id === '') return;
    calculateTotalSteps(Paths);
    if (Paths[id]) {
      setCurrentJourney(Paths[id][location.pathname]);
    } else setCurrentJourney(steppersRouterDefault[id][location.pathname]);

    setTotalSteps(calculateTotalSteps(Paths));
  }, [id]);

  useEffect(() => {
    calculateTotalSteps(Paths);
    if (Object.keys(Paths)?.includes(id)) {
      setCurrentJourney(Paths[id][location.pathname] ? Paths[id][location.pathname] : 1);
      setTotalSteps(calculateTotalSteps(Paths));
    }
  }, [location]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <StepperNumbers sx={{ height: 27, padding: '7px 11px', display: 'flex', alignItems: 'center', mr: 1 }}>
          <Box sx={{ display: 'flex' }}>
            <Box>{currentJourney}</Box>
            <Box sx={{ ml: 0.5, mr: 0.5 }}>{separator}</Box>
            <Box>{totalSteps}</Box>
          </Box>
        </StepperNumbers>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>{text}</Box>
        <Box>{iconEnd}</Box>
      </Box>
    </>
  );
};

export default Stepper;

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

export const CardItemPayment = styled(Card)(({ theme }) => ({
  borderRadius: '8px',
  border: `1px solid ${theme.palette.neutrals.beige4}`,
  backgroundColor: 'transparent',
  boxShadow: 'none'
}));

export const GridItemPayment = styled(Grid)(({ theme }) => ({
  '&.item-0, &.item-1': {
    paddingBottom: theme.spacing(3)
  },
  '&.item-1, &.item-3': {
    paddingLeft: theme.spacing(3)
  },
  [theme.breakpoints.down('lg')]: {
    '&.item-1, &.item-3': {
      paddingLeft: 0
    },
    '&.item-2': {
      paddingBottom: theme.spacing(3)
    }
  },
  [theme.breakpoints.down('md')]: {
    '&.item-0, &.item-1': {
      paddingBottom: theme.spacing(2)
    },
    '&.item-2': {
      paddingBottom: theme.spacing(2)
    }
  }
}));

import { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Wrapper } from './styled';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate, useParams } from 'react-router-dom';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { useGetNeedMutation } from '../../../services/needs.service';
import { formatCurrency } from '../../../validations/utils';
import CircularProgress from '@mui/material/CircularProgress';
import TagsWithFunction from '../../atoms/TagsWithFunction';
import ModalColsultaOnline from '../../molecules/Modais/ModalConsultaOnline';
import ModalExameAMH from '../Modais/ModalExameAMH';
import ModalConsultaPresencial from '../Modais/ModalConsultaPresencial';
import ModalConstaExames from '../../molecules/Modais/ModalConstaExames';
import { ECalendarType } from '../../../services/product.service';
import { dynamicCustomEventTracking } from '../../../lib/gtm';
import ListItems from './ListItems';

export interface ServiceDetails {
  title: string;
  url: string;
  price: string;
  itensText: Array<{ item: string }>;
  text?: JSX.Element;
  tags: {
    saoPaulo?: boolean;
    examAMH?: boolean;
    online?: boolean;
  };
}

interface Modais {
  [key: string]: boolean;
}

export const CardChoiceService: FC = () => {
  const [open, setOpen] = useState<Modais>({
    exameAMH: false,
    consultaOnline: false,
    consultaPresencial: false,
    consultaExames: false
  });

  const handleOpen = (type: string) => {
    setOpen({ ...open, [type]: !open[type] });
  };
  const { serviceId } = useParams();
  const [getNeedMutation, { data, isLoading }] = useGetNeedMutation();
  const [valueModal, setValueModal] = useState<string>('');
  const navegate = useNavigate();
  const findCalendarId = (product: any): string => {
    const typeMain = product.calendarTypes.find((item: any) => item.type === ECalendarType.MAIN);
    const typeBoth = product.calendarTypes.find((item: any) => item.type === ECalendarType.BOTH);
    let calendarId = '';
    if (typeBoth) {
      calendarId = typeBoth.calendarId;
    } else if (typeMain) {
      calendarId = typeMain.calendarId;
    } else {
      calendarId = product.calendars[0].id;
    }
    return calendarId;
  };

  useEffect(() => {
    if (serviceId) getNeedMutation({ serviceId });
  }, []);

  const verifyDF = (id: string) => {
    if (id === process.env.REACT_APP_PRODUCT_DF?.split('/')[0]) {
      return true;
    }
  };

  useEffect(() => {
    switch (valueModal) {
      case 'exame AHM':
        handleOpen('exameAMH');
        break;
      case 'online':
        handleOpen('consultaOnline');
        break;
      case 'São Paulo':
        handleOpen('consultaPresencial');
        break;
      case '+40 exames':
        handleOpen('consultaExames');
        break;
      default:
        '';
    }
  }, [valueModal]);

  return (
    <>
      {!isLoading ? <></> : <CircularProgress sx={{ color: 'brand.darkGreen' }} />}
      {data?.products.map((resp, key) => {
        const scheduleCalendarId: string = findCalendarId(resp);
        return (
          <Wrapper mb={3} key={resp.name}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Box>
                <Box mb={1}>
                  <Typography variant="body1B">{resp.alias ? resp.alias : resp.name}</Typography>
                </Box>
                <Box sx={{ paddingRight: { sx: 0, md: 4 } }}>
                  <Typography variant="body2R">{resp.shortDescription}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Button
                  variant="contained"
                  sx={{ padding: '12px 16px 14px 24px' }}
                  onClick={() => {
                    dynamicCustomEventTracking.send({ dynamicEventName: 'journey_add_to_card_ag' });
                    if (verifyDF(resp.id)) {
                      navegate(`/servico/${resp.id}/disponibilidade`);
                    } else navegate(`/servico/${resp.id}`);
                  }}
                >
                  <Typography variant="body1B" sx={{ mr: 1.5 }}>
                    Selecionar
                  </Typography>
                  <ArrowForwardIcon />
                </Button>
              </Box>
            </Box>
            <Box>
              {resp.services ? (
                <>
                  <ListItems title="O que está incluso?" items={resp.services.split(';')} />
                  <Divider />
                </>
              ) : null}

              {resp.insurance ? (
                <>
                  <ListItems title="Planos de saúde conveniados" items={resp.insurance} />
                  <Divider />
                </>
              ) : null}
            </Box>

            <Box mt={2}>{resp.tags ? <TagsWithFunction tags={resp.tags} setValueModal={setValueModal} /> : null}</Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, alignItems: 'flex-end' }}>
              <Box>
                <Typography variant="body2B">
                  {formatCurrency(resp.price, 'BRL')} em até {resp.maxInstallment}x
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TimerOutlinedIcon sx={{ fontSize: '16px' }} />
                <Typography ml={0.5} variant="body2R">
                  {resp.durationTime ||
                    resp.calendars.filter((item: any) => item.id === scheduleCalendarId)[0].durationEvent}
                  min
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' }, mt: 3 }}>
              <Button
                variant="contained"
                sx={{ padding: '12px 16px 14px 24px', justifyContent: 'space-between' }}
                fullWidth
                onClick={() => {
                  dynamicCustomEventTracking.send({ dynamicEventName: 'journey_add_to_card_ag' });
                  if (verifyDF(resp.id)) {
                    navegate(`/servico/${resp.id}/disponibilidade`);
                  } else navegate(`/servico/${resp.id}`);
                }}
              >
                <Typography variant="body1B" sx={{ mr: 1.5 }}>
                  Selecionar
                </Typography>
                <ArrowForwardIcon />
              </Button>
            </Box>
            <ModalColsultaOnline
              openCloseController={open.consultaOnline}
              onCloseHandler={() => {
                handleOpen('consultaOnline');
                setValueModal('');
              }}
            />
            <ModalExameAMH
              openCloseController={open.exameAMH}
              onCloseHandler={() => {
                handleOpen('exameAMH');
                setValueModal('');
              }}
            />
            <ModalConsultaPresencial
              openCloseController={open.consultaPresencial}
              onCloseHandler={() => {
                handleOpen('consultaPresencial');
                setValueModal('');
              }}
            />
            <ModalConstaExames
              openCloseController={open.consultaExames}
              onCloseHandler={() => {
                handleOpen('consultaExames');
                setValueModal('');
              }}
            />
          </Wrapper>
        );
      })}
    </>
  );
};

export default CardChoiceService;

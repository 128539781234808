import { createSlice } from '@reduxjs/toolkit';
import { ICalaendarType } from '../../services/product.service';
import { IHealthPlanInsurance } from '../../services/healthPlan.service';

export interface CalendarDetails {
  id: string;
  calendarType: string;
  durationEvent: number;
  userId?: string;
}

export interface ServiceAvailableCity {
  calendarId: string;
  city: string;
}
export interface ServiceSteps {
  service: { id: string; name: string; order: number; subname: string; description: string; isOyaService: boolean };
}

export interface IInsurances {
  id: string;
  name: string;
  healthPlanId: string;
}

export interface ProductState {
  name: string;
  alias: string;
  id: string;
  calendar: CalendarDetails;
  services: string;
  servicesSteps: Array<ServiceSteps>;
  price: number;
  installment: number;
  isNeedAddress: boolean;
  urlAnamnese: string;
  sendAnamnese: boolean;
  isServiceAvailableCityFromZipCode: boolean;
  availableCity: Array<ServiceAvailableCity>;
  calendarTypes: Array<ICalaendarType>;
  healthPlan: Array<IHealthPlanInsurance>;
  durationTime: number;
  doctorName?: string;
}

const initialState: ProductState = {
  name: '',
  alias: '',
  id: '',
  calendar: {
    id: '',
    calendarType: '',
    durationEvent: 0
  },
  services: '',
  servicesSteps: [],
  price: 0,
  installment: 6,
  isNeedAddress: false,
  urlAnamnese: '',
  sendAnamnese: false,
  isServiceAvailableCityFromZipCode: true,
  availableCity: [],
  calendarTypes: [],
  healthPlan: [
    {
      createdAt: '',
      healthPlan: {
        id: '',
        name: '',
        company: '',
        active: false,
        createdAt: new Date(),
        updatedAt: new Date()
      },
      id: '',
      name: '',
      updatedAt: '',
      companyExclusive: '',
      showInSchedule: false
    }
  ],
  durationTime: 0
};

// com o toolkit essa função substitui o reducer
export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    saveProductDetail: (state, action) => {
      return (state = { ...initialState, ...action.payload });
    },
    saveCalendarDetails: (state, action) => {
      state.calendar = action.payload;
    },
    saveAvailableCity: (state, action) => {
      state.availableCity = action.payload;
    },
    isServiceAvailableCityFromZipCode: (state, action) => {
      state.isServiceAvailableCityFromZipCode = action.payload;
    }
  }
});

export const { saveProductDetail, saveCalendarDetails, saveAvailableCity, isServiceAvailableCityFromZipCode } =
  productSlice.actions;
export const productReducer = productSlice.reducer;

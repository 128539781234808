import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const BoxStyled = styled(Box)(({ theme }) => () => ({
  backgroundColor: theme.palette.neutrals.beige1,
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '568px',
  boxShadow: '5px',
  padding: '30px',
  top: '30%',
  marginTop: 'min(80px, 10%)',
  borderRadius: '15px',
  outline: 'none',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    transform: 'none',
    top: 'initial',
    bottom: 0,
    left: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  }
}));

import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import session from 'redux-persist/lib/storage/session';
import { persistReducer, createTransform } from 'redux-persist';

import { scheduleReducer } from './features/schedule/schedule-slice';
import { productReducer } from './features/product/product-slice';
import { nextPageReducer } from './features/stepper/stepper-slice';
import { paymentReducer } from './features/payment/payment-slice';
import { userReducer } from './features/user/user-slice';
import { api } from './services/api.service';
import { paymentDetailReducer } from './features/paymentDetail/paymentDetail-slice';
import { personalDataReducer } from './features/personalData/personalData-slice';
import { personalDataAddressReducer } from './features/personalDataAddress/personalDataAddress-slice';
import { redirectPaymentReducer } from './features/redirectPayment/redirectPayment-slice';
import { queryStringReducer } from './features/queryString/queryString-slice';

const rootReducer = combineReducers({
  schedule: scheduleReducer,
  product: productReducer,
  nextPage: nextPageReducer,
  payment: paymentReducer,
  paymentDetail: paymentDetailReducer,
  user: userReducer,
  personalDataInfo: personalDataReducer,
  personalDataAddressInfo: personalDataAddressReducer,
  redirectPayment: redirectPaymentReducer,
  queryString: queryStringReducer,
  [api.reducerPath]: api.reducer
});

const paymentTransform = createTransform(
  (inboundState: object) => {
    return {
      ...(inboundState as object),
      method: ''
    };
  },
  null,
  { whitelist: ['payment'] }
);

const persistConfig = {
  key: 'root',
  storage: session,
  blacklist: ['personalDataInfo', 'personalDataAddressInfo'],
  transforms: [paymentTransform]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
    preloadedState
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

import { FC, SyntheticEvent, useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import { AlertStyled } from './styled';

interface Props {
  textButton?: string;
  text: JSX.Element | string;
  type: 'error' | 'warning' | 'info' | 'success';
  icon?: boolean;
  oneOpen?: 'true';
  noTimer?: boolean;
  handler?: () => void | null;
}
export const AlertMessage: FC<Props> = ({ text, textButton, type, icon, oneOpen, noTimer, handler }) => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(false);
    if (handler) handler();
  };

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    if (handler) handler();
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={noTimer ? null : 8000}
        onClose={handleClose}
        key={oneOpen === 'true' ? oneOpen : Math.random()}
      >
        <AlertStyled
          icon={icon}
          variant="filled"
          severity={type}
          sx={{ width: '100%' }}
          action={
            <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '125px', md: '100%' } }}>
              <Button color="inherit" size="small" sx={{ height: '50px' }} onClick={handleClick}>
                {textButton}
              </Button>
            </Box>
          }
        >
          {text}
        </AlertStyled>
      </Snackbar>
    </>
  );
};

export default AlertMessage;

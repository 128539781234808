import { createSlice } from '@reduxjs/toolkit';
interface PaymentState {
  method: string;
  totalToPay: number;
  discountCupom: number;
  typeCupomDiscount: string;
  nameCupom: string;
}

const initialState: PaymentState = {
  method: '',
  totalToPay: 0,
  discountCupom: 0,
  typeCupomDiscount: '',
  nameCupom: ''
};

// com o toolkit essa função substitui o reducer
export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    savePaymentMethod: (state, action) => {
      state.method = action.payload;
    },
    saveTotalToPay: (state, action) => {
      state.totalToPay = action.payload;
    },
    applyDiscount: (state, action) => {
      state.discountCupom = action.payload;
    },
    saveTypeCupomDiscount: (state, action) => {
      state.typeCupomDiscount = action.payload;
    },
    saveNameCupomDiscount: (state, action) => {
      state.nameCupom = action.payload;
    },
    resetPayment: state => {
      state.method = '';
      state.totalToPay = 0;
      state.discountCupom = 0;
      state.typeCupomDiscount = '';
      state.nameCupom = '';
    }
  }
});

export const {
  savePaymentMethod,
  saveTotalToPay,
  applyDiscount,
  saveTypeCupomDiscount,
  saveNameCupomDiscount,
  resetPayment
} = paymentSlice.actions;
export const paymentReducer = paymentSlice.reducer;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

export const WrapperStepper = styled(Box)(({ theme }) => ({
  marginBottom: '45px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '24px'
  }
}));

export const CardGridStyled = styled(Box)(() => ({
  marginBottom: '24px'
}));

export const LinkStyled = styled(Link)(() => ({
  textDecoration: 'none'
}));

import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckIcon from '@mui/icons-material/Check';
import ModalFindCep from '../../components/molecules/ZipCode/ModalFindCep';
import MaskedInput from 'react-text-mask';
import { useGetCepMutation } from '../../services/cep.service';
import { RootState } from '../../store';
import {
  ServiceAvailableCity,
  isServiceAvailableCityFromZipCode,
  saveProductDetail
} from '../../features/product/product-slice';
import { enabledBackButtonOnSchedulePage } from '../../features/stepper/stepper-slice';
import { ViewContent, ViewContentCenter, ViewRoot, ViewSidebar } from '../../layout/Main/styled';
import { saveTotalToPay } from '../../features/payment/payment-slice';

interface ICep {
  cep: string;
}

interface IFormCep {
  availableCity?: Array<ServiceAvailableCity>;
}

const ServiceAvailabilityByZIPCode = ({ availableCity = [] }: IFormCep) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productId } = useParams();
  const [getCepInfo, { data, isLoading }] = useGetCepMutation();
  const { product } = useSelector((state: RootState) => state);
  const [toggleModal, setToggleModal] = useState(false);
  const [isIsInValid, setIsInValid] = useState(true);
  const serviceAvailableCity = availableCity.length > 0 ? availableCity : product.availableCity;
  const MAX_LENGTH_CEP = 9;
  const {
    register,
    trigger,
    control,
    getValues,
    formState: { errors }
  } = useForm<ICep>();

  const submit = () => {
    getCepInfo({ cep: getValues('cep') });
  };

  const hasServiceAvailable = (city: string): boolean => {
    const currentCity = serviceAvailableCity.find(item => item.city === city);
    if (currentCity) return true;
    return false;
  };

  const getServiceByCity = (city: string): ServiceAvailableCity => {
    const currentCity = serviceAvailableCity.find(item => item.city === city);
    if (currentCity) return currentCity;
    return { city: '', calendarId: '' };
  };

  useEffect(() => {
    //Hotfix para forçar a request do produto na pagina de agendamento
    dispatch(
      saveProductDetail({
        ...product,
        id: ''
      })
    );
    dispatch(saveTotalToPay(0));
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(enabledBackButtonOnSchedulePage(true));
      if (hasServiceAvailable(data.localidade)) {
        dispatch(isServiceAvailableCityFromZipCode(true));
        navigate(`/servico/${productId}/${getServiceByCity(data.localidade).calendarId}`);
      } else {
        dispatch(isServiceAvailableCityFromZipCode(false));
        navigate(`/servico/${process.env.REACT_APP_SCHEDULE_DF_WITHOUT_EXAM}`);
      }
    }
  }, [data]);

  const EndAdornmentIcon = () => {
    if (errors?.cep) return <ErrorOutlineIcon />;
    if (getValues('cep')?.length === 9) return <CheckIcon />;
    return null;
  };

  return (
    <>
      <ViewRoot>
        <ViewContent className="ViewContent">
          <ViewContentCenter>
            <Typography variant="h2" sx={{ mb: 1 }}>
              O primeiro passo para a Descoberta da Fertilidade é o exame AMH
            </Typography>
            <Typography variant="body1R" sx={{ mb: 3 }}>
              Confira a disponibilidade na sua região.
            </Typography>
            <form id="form-2" onSubmit={e => e.preventDefault()} autoComplete="on">
              <Typography variant="body6" sx={{ marginTop: '25px' }}>
                Digite o seu CEP
              </Typography>

              <Controller
                name="cep"
                control={control}
                rules={{
                  required: 'Campo obrigatório'
                }}
                render={() => (
                  <MaskedInput
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                    className="form-control"
                    guide={false}
                    id="billingAddress.cep"
                    render={(ref, props) => (
                      <TextField
                        {...props}
                        inputRef={ref}
                        id="cep"
                        helperText={
                          <>
                            {errors?.cep && (
                              <Box component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                {errors?.cep?.message}

                                <Button
                                  variant="inline"
                                  color="primary"
                                  sx={{
                                    padding: 0
                                  }}
                                  onClick={() => setToggleModal(!toggleModal)}
                                >
                                  Não encontrou o CEP?
                                </Button>
                              </Box>
                            )}
                          </>
                        }
                        error={errors?.cep && true}
                        type="tel"
                        autoComplete="postal-code"
                        variant="standard"
                        inputProps={{
                          maxLength: 9,
                          classes: {
                            input: 'multilineColor'
                          },
                          'data-testid': 'cep'
                        }}
                        InputProps={{
                          endAdornment: <EndAdornmentIcon />
                        }}
                        fullWidth
                        placeholder="00000-000"
                        {...register('cep', {
                          onBlur: () => {
                            trigger('cep');
                          },
                          onChange: () => {
                            if (getValues('cep').length < MAX_LENGTH_CEP) {
                              setIsInValid(true);
                            }
                            if (getValues('cep').length === MAX_LENGTH_CEP) {
                              setIsInValid(false);
                              trigger('cep');
                            }
                          },
                          minLength: {
                            value: MAX_LENGTH_CEP,
                            message: 'CEP inválido'
                          },
                          required: {
                            value: true,
                            message: 'Campo obrigatório'
                          }
                        })}
                      />
                    )}
                  />
                )}
              />

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, mb: 40 }}>
                <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
                  <ArrowBackIcon />
                  <Typography variant="body1" sx={{ ml: 1.5 }}>
                    Voltar
                  </Typography>
                </Button>
                <LoadingButton
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => submit()}
                  loading={isLoading}
                  disabled={isIsInValid || !!errors.cep}
                >
                  Continuar
                </LoadingButton>
              </Box>
              <ModalFindCep openCloseController={toggleModal} onCloseHandler={() => setToggleModal(!toggleModal)} />
            </form>
          </ViewContentCenter>
        </ViewContent>
        <ViewSidebar withimage="true" component="aside" className="ViewSidebar">
          <Typography color="neutrals.beige1" variant="h1">
            O que você precisa hoje?
          </Typography>
        </ViewSidebar>
      </ViewRoot>
    </>
  );
};

export default ServiceAvailabilityByZIPCode;

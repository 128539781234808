import { FC } from 'react';
import { Circle } from './styled';

interface Props {
  icon: any;
}

export const CircleIcon: FC<Props> = ({ icon }) => {
  return <Circle>{icon}</Circle>;
};

export default CircleIcon;

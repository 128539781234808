import Typography from '@mui/material/Typography';
import BasicModal from '../../Modal';

interface ModalReembolsoProps {
  openCloseController: boolean;
  onCloseHandler: () => void;
}

const ModalConsultaOnline = ({ openCloseController, onCloseHandler }: ModalReembolsoProps) => {
  return (
    <>
      <BasicModal open={openCloseController} onCloseHandler={onCloseHandler} title="Como funciona a consulta online?">
        <Typography variant="body2R">
          A consulta médica online funciona da mesma forma que presencial, só que por videochamada. Você só precisa de um
          celular, tablet ou computador com internet, câmera e microfone.
        </Typography>
        <Typography variant="body2R" sx={{ mt: 2 }}>
          Durante a consulta, você vai conversar sobre seu momento atual, tirar dúvidas e entender qual o melhor método
          contraceptivo a partir do seu histórico de saúde, estilo de vida e necessidades.
        </Typography>
        <Typography variant="body2R" sx={{ mt: 2 }}>
          Se precisar de algum exame, te enviamos uma receita digital com todas as orientações no momento da sua consulta
          online. Você pode agendar seus exames no laboratório que quiser e enviar o resultado para a Oya.
        </Typography>
      </BasicModal>
    </>
  );
};

export default ModalConsultaOnline;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BoxdWithSpacing } from '../../organisms/Grid';

export const Wrapper = styled(BoxdWithSpacing)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.beige2,
  padding: '56px 0 '
}));

export const WrapperButtoon = styled(Box)(({ theme }) => ({
  textAlign: 'right',
  [theme.breakpoints.down('md')]: {
    textAlign: 'left'
  }
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: '40px'
  }
}));

export const ButtonPrimaryWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  [theme.breakpoints.down('md')]: {
    marginBottom: '16px'
  }
}));

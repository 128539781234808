import { PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { ModalWrapper, ModalContent, ModalExtraContent, ModalHeader, ModalBoxContent } from './style';

interface ModalProps {
  title?: string | undefined;
  open: boolean;
  onCloseHandler: () => void;
  extraContent?: React.ReactNode;
}

const BasicModalTwo = ({ open, onCloseHandler, title, children, extraContent }: PropsWithChildren<ModalProps>) => {
  return (
    <Modal
      keepMounted
      open={open}
      onClose={onCloseHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapper>
        <ModalContent>
          <ModalHeader>
            <CloseIcon onClick={onCloseHandler} sx={{ cursor: 'pointer' }} />
          </ModalHeader>
          <ModalBoxContent sx={{ px: '1px' }} id="modal-modal-description">
            {title && (
              <Typography id="modal-modal-title" variant="h2" sx={{ mt: 2 }}>
                {title}
              </Typography>
            )}
            {children}
          </ModalBoxContent>
        </ModalContent>
        {extraContent && <ModalExtraContent>{extraContent}</ModalExtraContent>}
      </ModalWrapper>
    </Modal>
  );
};

export default BasicModalTwo;

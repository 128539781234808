import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PersonalDataState {
  email: string;
  mobileNumber: string;
  fullName: string;
  nickName?: string;
  birthDate: string;
  cpf: string;
  howMeet: string;
  howMeetOther?: string;
}

const initialState: PersonalDataState = {
  email: '',
  mobileNumber: '',
  fullName: '',
  nickName: '',
  birthDate: '',
  cpf: '',
  howMeet: '',
  howMeetOther: ''
};

export const personalDataSlice = createSlice({
  name: 'personalDataState',
  initialState,
  reducers: {
    savePersonalData: (state, action: PayloadAction<PersonalDataState>) => {
      return (state = { ...initialState, ...action.payload });
    }
  }
});

export const { savePersonalData } = personalDataSlice.actions;
export const personalDataReducer = personalDataSlice.reducer;

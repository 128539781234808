import { SectionRoot } from '../../components/Grid';
import { styled } from '@mui/material/styles';

const Wrapper = styled(SectionRoot)(({ theme }) => ({
  backgroundColor: theme.palette.brand.darkGreen,
  width: '100%',
}));

type Props = {
  children: React.ReactNode;
};

const Main = ({ children }: Props) => (
  <Wrapper component="main">
    {children}
  </Wrapper>
);

export default Main;

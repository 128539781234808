import { ServiceSteps } from '../features/product/product-slice';
import { api } from './api.service';
import { IHealthPlanInsurance } from './healthPlan.service';

interface IProductQuery {
  id: string | undefined;
  doctorId?: string | undefined;
}

export enum ECalendarType {
  'MAIN' = 'MAIN',
  'RETURN' = 'RETURN',
  'BOTH' = 'BOTH'
}
export interface ICalaendarType {
  calendarId: string;
  createdAt: string;
  id: string;
  productId: string;
  type: ECalendarType;
  updatedAt: string;
}
export interface IProductResult {
  id: string;
  services: string[];
  servicesSteps: Array<ServiceSteps>;
  price: number;
  isNeedAddress: boolean;
  maxInstallment: number;
  urlAnamnese: string;
  sendAnamnese: boolean;
  calendars: {
    id: string;
    calendarType: string;
    durationEvent: number;
    city: string;
  }[];
  name: string;
  alias: string;
  calendarTypes: Array<ICalaendarType>;
  healthPlan: Array<IHealthPlanInsurance>;
  durationTime: number;
  doctorName: string;
}

export const product = api.injectEndpoints({
  endpoints: build => ({
    getProductDetails: build.mutation<IProductResult, IProductQuery>({
      query: ({ id, doctorId }) => ({
        url: `/product/${id}${doctorId ? `/${doctorId}` : ''}`
      })
    })
  })
});

export const { useGetProductDetailsMutation } = product;

import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContentWrapper, DialogTitleWrapper, StyledDialog } from './styled';

interface ModalProps {
  openCloseController: boolean;
  onCloseHandler: () => void;
}

const Modal = ({ openCloseController, onCloseHandler }: ModalProps) => {
  return (
    <>
      <StyledDialog
        open={openCloseController}
        onClose={onCloseHandler}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          sx: {
            margin: { xs: '80px 0px 0px 0px', md: '40px 0px 40px 0px' },
            position: { xs: 'absolute', md: 'relative' },
            left: '0px',
            right: '0px',
            bottom: '0px',
            maxWidth: { xs: '100%', md: '780px' }
          }
        }}
      >
        <DialogTitleWrapper id="scroll-dialog-title">
          <CloseIcon onClick={onCloseHandler} sx={{ cursor: 'pointer' }} />
        </DialogTitleWrapper>
        <DialogContentWrapper>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1} component="div">
            <Typography variant="h3" sx={{ mb: 2 }} component="p">
              Exames do check-up
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              O Check-up ginecológico da Oya Care inclui a realização dos exames
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, ml: -2 }} component="ul">
              <li>Hemograma completo</li>
              <li>Glicemia em jejum</li>
              <li>Hormônio tireoestimulante (TSH)</li>
              <li>T4 livre</li>
              <li>Anti-tireoperoxidase (anti-TPO)</li>
              <li>Anti-tireoglobulina</li>
              <li>Anticorpo anti-receptor de TSH (TRAB)</li>
              <li>25-hidroxi-vitamina D</li>
              <li>Anti-HBs</li>
              <li>HBsAg</li>
              <li>Anti-HCV</li>
              <li>Anti-HIV 1 e 2</li>
              <li>VDRL</li>
              <li>FSH</li>
              <li>LH</li>
              <li>Progesterona</li>
              <li>Prolactina</li>
              <li>Citologia oncótica em meio líquido (papanicolau)</li>
              <li>Ultrassonografia transvaginal</li>
              <li>Rotina de urina</li>
              <li>Cultura, urina com contagem de colônias</li>
              <li>Colesterol Total</li>
              <li>HDL Colesterol</li>
              <li>LDL Colesterol</li>
              <li>Uréia</li>
              <li>Creatinina</li>
              <li>TGP (ALANINA AMINOTRANSFERASE-ALT)</li>
              <li>TGO (ASPARTATO AMINOTRANSFERASE-AST)</li>
              <li>Exame antimulleriano (AMH)</li>
              <li>HPV captura híbrida</li>
              <li>PCR - chlamydia, neisseria, trichomonas, micoplasma, ureaplasma</li>
              <li>Pesquisa de fungos</li>
              <li>Bacterioscópico de secreção vaginal</li>
              <li>Triglicérides</li>
              <li>Antibiograma</li>
              <li>CA-125</li>
              <li>Ferro sérico</li>
              <li>Ferritina</li>
            </Typography>
          </DialogContentText>
        </DialogContentWrapper>
      </StyledDialog>
    </>
  );
};

export default Modal;

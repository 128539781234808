import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const WrapperActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '56px',
  marginTop: '40px',
  [theme.breakpoints.down('md')]: {
    marginTop: '24px'
  }
}));

import Typography from '@mui/material/Typography';
import BasicModal from '../../Modal';
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { eventTracking } from '../../../../lib/gtm';

interface Props {
  openCloseController: boolean;
  onCloseHandler: () => void;
}

const ModalFindCep = ({ openCloseController, onCloseHandler }: Props) => {
  return (
    <>
      <BasicModal open={openCloseController} onCloseHandler={onCloseHandler} title="Não encontrou seu cep?">
        <Typography variant="body2R" sx={{ mb: 3}}>
          Entre em contato com a gente por WhatsApp e nossa equipe de cuidados pode entender melhor onde você está e se nossa equipe está apta a te atender!
        </Typography>

        <Button
          variant="contained"
          href="https://bit.ly/Fale_com_a_Oya"
          target="_blank"
          endIcon={<WhatsAppIcon />}
          onClick={() => {
            eventTracking.click({
              category: 'Modal CEP',
              label: ' Entrar em contato'
            });
          }}
        >
            Entrar em contato
        </Button>
      </BasicModal>
    </>
  );
};

export default ModalFindCep;

import { api } from './api.service';
import { ICalaendarType } from './product.service';

export interface INeedsResult {
  id: string;
  name: string;
  route: string;
  products: [];
}
[];

export interface IdNeed {
  serviceId?: string;
}

export interface INeedResult {
  id: string;
  name: string;
  products: [
    {
      durationTime: number | null;
      calendars: [
        {
          durationEvent: number;
        }
      ];
      calendarTypes: Array<ICalaendarType>;
      id: string;
      name: string;
      price: number;
      services: string;
      insurance?: string[];
      tags: null;
      alias: null;
      reimbursementValue: number;
      nextStepsAfterPayment: string;
      shortDescription: string;
      emailTemplatePayment: string;
      active: boolean;
      sort: number;
      primaryColor: string;
      imageHeaderCard: string;
      linkBeforeSchedule: string;
      urlAnamnese: string;
      invoiceDescription: string;
      template: string;
      omieProductCode: string;
      omieProductCategory: string;
      omieIntegrationActive: boolean;
      sendAnamnese: boolean;
      isNeedAddress: boolean;
      maxInstallment: number;
      isAdditionalProduct: boolean;
      isProceedure: boolean;
    }
  ];
}

export const needs = api.injectEndpoints({
  endpoints: build => ({
    getNeeds: build.mutation<INeedsResult[], any>({
      query: () => ({
        url: `/needs`
      })
    }),
    getNeed: build.mutation<INeedResult, IdNeed>({
      query: ({ serviceId }) => ({
        url: `/needs/${serviceId}`
      })
    })
  })
});

export const { useGetNeedMutation, useGetNeedsMutation } = needs;

import { Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chip from '@mui/material/Chip';
import { IAppointments, useAllAvailableDaysOfTheMonth, useShortedAvailableDays } from '../hooks';
import { AvailableDays as MuiAvailableDays } from '../styled';
import { formatAvailableDaysByDatePicker, formatShortedAvailableDays } from '../../../../lib/dayjs';
import {
  saveFirstDaysAvailable,
  saveSelectedDay,
  saveSelectedDayByDatePicker,
  updateScheduleEvents
} from '../../../../features/schedule/schedule-slice';
import { RootState } from '../../../../store';

const DesktopDatePicker = lazy(() => import('../../DesktopDatePicker'));
const MobileDatePicker = lazy(() => import('../../MobileDatePicker'));

interface AvailableDaysProps {
  updateMonth: (month: string) => void;
  updateYear: (year: string) => void;
  appointments: IAppointments;
  isLoading?: boolean;
}

const AvailableDays = ({ updateMonth, updateYear, appointments, isLoading = false }: AvailableDaysProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { selectedDay, selectedDayByDatePicker } = useSelector((state: RootState) => state.schedule);
  const [toggleCalendar, setToggleCalendar] = useState(false);
  const [shortedAvailableDays] = useShortedAvailableDays(appointments);
  const [allAvailableDaysOfTheMonth] = useAllAvailableDaysOfTheMonth(appointments);
  const [fisrtLoad, setFisrtLoad] = useState(true);

  useEffect(() => {
    if (!selectedDay && shortedAvailableDays?.length > 0 && fisrtLoad) {
      dispatch(saveSelectedDay(shortedAvailableDays[0]));
      dispatch(saveFirstDaysAvailable(shortedAvailableDays));
      setFisrtLoad(false);
    }
  }, [shortedAvailableDays, fisrtLoad]);

  useEffect(() => {
    if (toggleCalendar) {
      dispatch(updateScheduleEvents('calendar_opened'));
    }
  }, [toggleCalendar]);
  return (
    <MuiAvailableDays id="WrapperAvailableDays">
      {shortedAvailableDays.map((value, index) => {
        return (
          <Chip
            data-testid="available-days"
            key={index}
            label={formatShortedAvailableDays(value)}
            variant="outlined"
            sx={{
              display: 'block',
              textAlign: 'center'
            }}
            className={value === selectedDay && toggleCalendar == false ? 'selected' : ''}
            onClick={() => {
              dispatch(saveSelectedDay(value));
              dispatch(saveSelectedDayByDatePicker(false));
              setToggleCalendar(false);
              dispatch(updateScheduleEvents(`first_available_days_${index}`));
            }}
          />
        );
      })}
      {selectedDayByDatePicker && (
        <Chip
          data-testid="selected-day-by-date-picker"
          label={formatAvailableDaysByDatePicker(selectedDay)}
          variant="outlined"
          sx={{
            display: 'block',
            textAlign: 'center'
          }}
          className={`selected-day-by-date-picker ${selectedDayByDatePicker ? 'selected' : ''}`}
        />
      )}
      <Chip
        data-testid="open-datepicker"
        label="Escolher uma data"
        variant="outlined"
        className={toggleCalendar ? 'selected' : ''}
        sx={{ display: 'block', textAlign: 'center' }}
        onClick={() => {
          setToggleCalendar(!toggleCalendar);
          dispatch(saveSelectedDayByDatePicker(false));
        }}
      />
      {toggleCalendar && (
        <Suspense fallback={[]}>
          {isMobile ? (
            <MobileDatePicker
              toggleCalendar={toggleCalendar}
              setToggleCalendar={setToggleCalendar}
              updateMonth={updateMonth}
              updateYear={updateYear}
              availableDays={allAvailableDaysOfTheMonth}
              isLoading={isLoading}
            />
          ) : (
            <DesktopDatePicker
              updateMonth={updateMonth}
              updateYear={updateYear}
              availableDays={allAvailableDaysOfTheMonth}
            />
          )}
        </Suspense>
      )}
    </MuiAvailableDays>
  );
};

export default AvailableDays;

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { WrapperFooter, WrapperContentTwo } from './style';
import SessionLimiterStyled from '../../atoms/SessionLimiter/styled';

import AlreadyPayKnowPlataform from '../AlreadyPayKnowPlataform';

const ContentPaymentAlreadyDone = () => {
  return (
    <>
      <WrapperContentTwo>
        <AlreadyPayKnowPlataform />
      </WrapperContentTwo>
      <SessionLimiterStyled sx={{ marginTop: 0 }} />
      <WrapperFooter
        heightLess
        sx={{
          display: { xs: 'grid', md: 'flex' },
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ mb: { xs: 5, md: 0 } }}>
          <Typography variant="h3">Precisa de ajuda? </Typography>
        </Box>
        <Box sx={{ display: { xs: 'grid', md: 'flex' } }}>
          <Box sx={{ mb: { xs: 2, md: 0 } }}>
            <Button
              variant="contained"
              fullWidth
              endIcon={<WhatsAppIcon />}
              href="https://bit.ly/Fale_com_a_Oya"
              target="_blank"
            >
              Fale com a gente
            </Button>
          </Box>
          <Box sx={{ ml: { xs: 0, md: 2 }, mb: { xs: 5, md: 0 } }}>
            <Typography variant="h3">
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                endIcon={<ArrowForwardIcon />}
                href="https://www.oya.care/sobre/perguntas-frequentes?_ga=2.23775738.1589004085.1677176504-1625506865.1673358955"
                target="_blank"
              >
                Ver dúvidas frequentes
              </Button>
            </Typography>
          </Box>
        </Box>
      </WrapperFooter>
    </>
  );
};

export default ContentPaymentAlreadyDone;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.neutrals.beige4}`,
  padding: '24px',
  borderRadius: '16px'
}));

export const ButtonMoreLess = styled(Box)(({ theme }) => ({
  color: theme.palette.brand.darkGreen,
  width: 'max-content',
  ':hover': {
    color: `${theme.palette.brand.darkGreen}85`
  }
}));

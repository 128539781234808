import { useState } from 'react';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Accordion, AccordionSummary, Details, WrapperIcon } from './styled';

type ListToggle = boolean[];

type Props = {
  questions: {
    id: string;
    summary: string;
    details: JSX.Element;
  }[];
};

const BaseAccordion = ({ questions }: Props) => {
  const [listToggle, setListToggle] = useState<ListToggle>(new Array(questions.length).fill(false));
  return (
    <>
      {questions.map((question, index) => (
        <Accordion
          key={question.summary}
          onChange={(_event, expanded) => {
            setListToggle({
              ...listToggle,
              [index]: expanded
            });
          }}
        >
          <AccordionSummary
            expandIcon={
              <WrapperIcon sx={{ display: 'flex', padding: 0.5 }}>
                {listToggle[index] ? <RemoveIcon /> : <AddIcon />}
              </WrapperIcon>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body1B">{question.summary}</Typography>
          </AccordionSummary>
          <Details>{question.details}</Details>
        </Accordion>
      ))}
    </>
  );
};

export default BaseAccordion;

import { styled } from '@mui/material/styles';
import { SectionFullWidth } from '../../Grid';

type PropsWrapperFooter = {
  heightLess?: boolean;
};

export const WrapperContentTwo = styled(SectionFullWidth)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.neutrals.beige1
}));

export const WrapperFooter = styled(SectionFullWidth)<PropsWrapperFooter>(({ theme, heightLess }) => ({
  backgroundColor: theme.palette.neutrals.beige1,
  width: '100%',
  height: '162px',
  [theme.breakpoints.down('md')]: {
    height: heightLess ? '260px' : '303px'
  }
}));

import { api } from './api.service';

interface IAvailableTimesQuery {
  calendar: { id: string; calendarType: string; userId?: string };
  month: string;
  year: string;
}

export const availableTimes = api.injectEndpoints({
  endpoints: build => ({
    getAvailableTimes: build.mutation<any, IAvailableTimesQuery>({
      query: ({ calendar, month, year }) => ({
        url: `calendars/${calendar.id}${calendar.userId ? `/${calendar.userId}` : ''}/availableTimes/${month}/${year}${
          calendar.userId ? `/false` : ''
        }`
      })
    })
  })
});

export const { useGetAvailableTimesMutation } = availableTimes;

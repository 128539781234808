import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '../../entities/IUser';
import { IHealthPlanInsurance } from '../../services/healthPlan.service';
interface paymentDetailState {
  method: string;
  price: number;
  createdAt: string;
  installments: number;
  amount: number;
  discount: number;
  cupomName: string;
  billingAddress: {
    address: string;
    addressNumber: string;
    neighborhood: string;
    city: string;
    state: string;
  };
  healthPlan: {
    insurance: IHealthPlanInsurance;
    cardNumber: string;
    cardExpirationDate: string;
  };
  user: IUser;
}

const initialState: paymentDetailState = {
  method: '',
  price: 0,
  createdAt: '',
  installments: 1,
  amount: 0,
  discount: 0,
  cupomName: '',
  billingAddress: {
    address: '',
    addressNumber: '',
    neighborhood: '',
    city: '',
    state: ''
  },
  healthPlan: {
    insurance: {} as IHealthPlanInsurance,
    cardNumber: '',
    cardExpirationDate: ''
  },
  user: {} as IUser
};

export const paymentDetailSlice = createSlice({
  name: 'paymentDetail',
  initialState,
  reducers: {
    savePaymentDetail: (state, action) => {
      return (state = { ...initialState, ...action.payload });
    }
  }
});

export const { savePaymentDetail } = paymentDetailSlice.actions;
export const paymentDetailReducer = paymentDetailSlice.reducer;

import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Price, Refund, ScheduleSelectedDate, ServicesDetails, LiStyled, ButtonViewMore } from './styled';
import { ReactComponent as Calendar } from '../../../images/calendar.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatSelectedTime } from '../../../lib/dayjs';
import { RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import ModalRefund from './RefundModal';
import { useEffect, useState } from 'react';
import { saveTotalToPay } from '../../../features/payment/payment-slice';
import { formatCurrency } from '../../../validations/utils';
import theme from '../../quarks/Theme';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { PaymentFlowType } from '../../../Interfaces/PaymentFlowType.interface';
import { savePaymentDetail } from '../../../features/paymentDetail/paymentDetail-slice';

const ShoppingCart = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    schedule,
    product,
    payment,
    queryString: { flowType }
  } = useSelector((state: RootState) => state);
  const [totalPrice, setTotalPrice] = useState(payment.totalToPay);
  const dispatch = useDispatch();
  const [openViewMore, setOpenViewMore] = useState(false);

  const handleClick = () => {
    setOpenViewMore(!openViewMore);
  };

  useEffect(() => {
    let value = product.price;
    if (payment.typeCupomDiscount === 'PERCENTAGE') {
      value = product.price - (payment.discountCupom / 100) * product.price;
      setTotalPrice(value);
    } else if (payment.typeCupomDiscount === 'VALUE') {
      value = product.price - payment.discountCupom < 0 ? 0 : product.price - payment.discountCupom;
      setTotalPrice(value);
    } else setTotalPrice(value);
    dispatch(saveTotalToPay(value));
    dispatch(savePaymentDetail({ discount: product.price - value }));
  }, [payment.discountCupom, payment.totalToPay, payment.typeCupomDiscount]);

  const totalInstallment = (totalPrice / product.installment).toFixed(2);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Typography color="neutrals.beige1" variant="body1R" mb={1}>
        {product?.alias ? product.alias : product?.name}
      </Typography>

      {product.doctorName ? (
        <Typography color="neutrals.beige1" variant="body1R" mb={1}>
          Agendamento com Dra. {product.doctorName}
        </Typography>
      ) : (
        []
      )}

      <Price variant={isMobile ? 'body1R' : 'subtitle1'}>
        <span>
          <span className="value">{formatCurrency(totalPrice, 'BRL')} </span>
          {payment.typeCupomDiscount === 'VALUE' && payment.discountCupom > 0 && (
            <span className="value">{`(${formatCurrency(payment.discountCupom, 'BRL')} off)`} </span>
          )}
          {payment.typeCupomDiscount === 'PERCENTAGE' && payment.discountCupom > 0 && (
            <span className="value">{`(${payment.discountCupom}% off)`} </span>
          )}
        </span>
        {payment.totalToPay > 0 && (
          <Box sx={{ ml: { xs: 0.5, md: 0 } }} component="span">
            <span>
              {' '}
              ou até {product.installment}x de R$ {totalInstallment.replace('.', ',')}
            </span>
          </Box>
        )}
      </Price>
      <Refund
        sx={{ mb: 4 }}
        variant="body6"
        onClick={() => {
          handleOpen();
        }}
      >
        Pode ser reembolsável pelo seu plano de saúde <InfoOutlinedIcon />
      </Refund>
      <ModalRefund
        open={open}
        close={() => {
          handleOpen();
        }}
      />
      {schedule.dateStart && flowType === PaymentFlowType.DEFAULT && (
        <ScheduleSelectedDate variant="body2B">
          <Calendar /> <span>{formatSelectedTime(schedule.dateStart)}</span>
        </ScheduleSelectedDate>
      )}
      {flowType === PaymentFlowType.DEFAULT ? (
        <>
          <ServicesDetails sx={{ mt: 4, display: { xs: 'none', md: 'block' } }}>
            {product.servicesSteps?.map((serviceStep, index) => {
              return (
                <LiStyled key={index} isOyaService={serviceStep.service.isOyaService}>
                  {serviceStep.service.name}
                  <br />
                  {serviceStep.service.subname}
                  <Box mt={1}>
                    <Typography color="neutrals.beige4" variant="body2R">
                      {serviceStep.service.description}
                    </Typography>
                  </Box>
                </LiStyled>
              );
            })}
          </ServicesDetails>
          <Collapse in={openViewMore} timeout="auto" unmountOnExit>
            <ServicesDetails sx={{ mt: 4, display: { xs: 'block', md: 'none' } }}>
              {product.servicesSteps?.map((serviceStep, index) => {
                return (
                  <LiStyled key={index} isOyaService={serviceStep.service.isOyaService}>
                    {serviceStep.service.name}
                    <br />
                    {serviceStep.service.subname}
                    <Box mt={1}>
                      <Typography color="neutrals.beige4" variant="body2R">
                        {serviceStep.service.description}
                      </Typography>
                    </Box>
                  </LiStyled>
                );
              })}
            </ServicesDetails>
          </Collapse>
          <Box onClick={handleClick} sx={{ display: { xs: 'block', md: 'none' }, width: 'max-content' }}>
            {openViewMore ? (
              <ButtonViewMore sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Box>
                  <Typography variant="body2B">Esconder detalhes</Typography>
                </Box>
                <Box sx={{ height: '24px' }}>
                  <ExpandLessOutlinedIcon />
                </Box>
              </ButtonViewMore>
            ) : (
              <ButtonViewMore sx={{ display: 'flex', alignItems: 'center', marginTop: 2, cursor: 'pointer' }}>
                <Box>
                  <Typography variant="body2B">Ver detalhes</Typography>
                </Box>
                <Box sx={{ height: '24px' }}>
                  <ExpandMoreOutlinedIcon />
                </Box>
              </ButtonViewMore>
            )}
          </Box>
        </>
      ) : (
        []
      )}
    </>
  );
};

export default ShoppingCart;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface QueryStringState {
  journeyid: string;
  productid: string;
  flowType: string;
  additionalserviceid: string;
  returnurl: string;
  coupon: string;
  utms: {
    utm_campaign: string;
    utm_medium: string;
    utm_source: string;
  };
}

const initialState: QueryStringState = {
  journeyid: '',
  productid: '',
  flowType: '',
  additionalserviceid: '',
  returnurl: '',
  coupon: '',
  utms: {
    utm_campaign: '',
    utm_medium: '',
    utm_source: ''
  }
};

export const queryStringSlice = createSlice({
  name: 'querystring',
  initialState,
  reducers: {
    saveQueryString: (state, action: PayloadAction<QueryStringState>) => {
      return (state = { ...action.payload });
    }
  }
});

export const { saveQueryString } = queryStringSlice.actions;
export const queryStringReducer = queryStringSlice.reducer;

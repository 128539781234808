import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AvailableTimes as MuiAvailableTimes } from '../styled';
import { IAppointments, useScheduleAvailableTimeGroupedByPeriod } from '../hooks';
import { formatPeriodLabel, formatTimeLabel } from '../../../../lib/dayjs';
import { saveSelectedDate } from '../../../../features/schedule/schedule-slice';
import { goTo, updateValidStep } from '../../../../features/stepper/stepper-slice';
import { RootState } from '../../../../store';
import { customEventTracking } from '../../../../lib/gtm';
import { ChipHour } from './style';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
interface AvailableTimesProps {
  appointments: IAppointments;
  isLoading: boolean;
}

const AvailableTimes = ({ appointments, isLoading }: AvailableTimesProps) => {
  const dispatch = useDispatch();
  const { selectedDay, dateStart, events } = useSelector((state: RootState) => state.schedule);
  const { calendar, durationTime } = useSelector((state: RootState) => state.product);
  const [scheduleAvailableTimeGroupedByPeriod] = useScheduleAvailableTimeGroupedByPeriod(appointments, selectedDay);

  const formatLabelCalendarType = (type: string, duration: number) => {
    switch (type) {
      case 'EXAM':
        return `duração do exame: ${duration} minutos`;
      case 'VIRTUAL_CONSULTATION':
        return `duração da consulta: ${duration} minutos`;
      case 'PHYSICAL_APPOINTMENT':
        return `duração da consulta: ${duration} minutos`;
      case 'PROCEDURE':
        return `duração do procedimento: ${duration} minutos`;
    }
  };

  //Google Tag Manager - Event Tracking
  useEffect(() => {
    const timer = setTimeout(() => {
      customEventTracking.send({
        event: events,
        props: {
          day_available: selectedDay,
          available_times: `morning: ${
            scheduleAvailableTimeGroupedByPeriod?.morning?.availableList.length > 0 ? true : false
          }, afternoon: ${
            scheduleAvailableTimeGroupedByPeriod?.afternoon?.availableList.length > 0 ? true : false
          }, evening: ${scheduleAvailableTimeGroupedByPeriod?.evening?.availableList.length > 0 ? true : false}`
        }
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [scheduleAvailableTimeGroupedByPeriod, events]);
  return (
    <MuiAvailableTimes>
      {!isLoading ? <></> : <CircularProgress sx={{ color: 'brand.darkGreen' }} />}
      <>
        {Object.keys(scheduleAvailableTimeGroupedByPeriod).map((key, index) => {
          return (
            scheduleAvailableTimeGroupedByPeriod[key].availableList.length >= 1 && (
              <Box key={index} data-cy={`schedule-period-${key}`} sx={{ marginBottom: { xs: '24px', md: '30px' } }}>
                <Typography variant="body2R">{formatPeriodLabel(key)}</Typography>
                <Box>
                  {scheduleAvailableTimeGroupedByPeriod[key].availableList.map((value, index) => {
                    return (
                      <ChipHour
                        data-cy={`schedule-hour-${index}`}
                        key={index}
                        label={formatTimeLabel(value)}
                        variant="outlined"
                        className={value === dateStart ? 'selected' : ''}
                        onClick={() => {
                          dispatch(saveSelectedDate(value));
                          dispatch(goTo('/dados-pessoais'));
                          dispatch(updateValidStep({ schedule: true }));
                          customEventTracking.send({
                            event: 'calendar_touch_time',
                            props: {
                              touch_time: value
                            }
                          });
                        }}
                      />
                    );
                  })}
                </Box>
              </Box>
            )
          );
        })}

        <Typography variant="body2R">
          {formatLabelCalendarType(calendar.calendarType, durationTime ? durationTime : calendar.durationEvent)}
        </Typography>
      </>
    </MuiAvailableTimes>
  );
};

export default AvailableTimes;

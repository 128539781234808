import { styled } from '@mui/material/styles';
import { SectionFullWidth } from '../../Grid';
import Box from '@mui/material/Box';

type PropsWrapperFooter = {
  heightLess?: boolean;
};

type WrapperPros = {
  small?: string;
};

export const Wrapper = styled(SectionFullWidth)<WrapperPros>(({ theme, small }) => ({
  backgroundColor: theme.palette.brand.darkGreen,
  width: '100%',
  height: small === 'true' ? '384px' : '150px',
  [theme.breakpoints.down('md')]: {
    height: small === 'true' ? '434px' : '200px'
  }
}));

export const WrapperContent = styled(SectionFullWidth)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.neutrals.beige3
}));

export const WrapperContentTwo = styled(SectionFullWidth)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.neutrals.beige1
}));

export const WrapperFooter = styled(SectionFullWidth)<PropsWrapperFooter>(({ theme, heightLess }) => ({
  backgroundColor: theme.palette.neutrals.beige1,
  width: '100%',
  height: '162px',
  [theme.breakpoints.down('md')]: {
    height: heightLess ? '260px' : '303px'
  }
}));

export const PaperCardContent = styled(Box)(({ theme }) => ({
  marginTop: '-118px',
  [theme.breakpoints.down('md')]: {
    marginTop: '-136px'
  },
  backgroundColor: theme.palette.neutrals.beige1,
  // border: `1px solid ${theme.palette.neutrals.beige4}`,
  borderRadius: '16px 16px 0px 0px',
  padding: '24px',
  borderBottom: '0px'
}));

export const PaperCard = styled(Box)(() => ({ zIndex: '9' }));

export const PaperCardFooter = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.neutrals.beige4}`,
  padding: '24px',
  borderBottom: '0px'
}));

export const PaperCardFooterDetail = styled(Box)(() => ({}));

export const PaperCardFooterImage = styled(Box)(() => ({}));

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Tag = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutrals.beige3,
  borderRadius: '4px',
  padding: '5px 11px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: `${theme.palette.neutrals.beige3}85`
  }
}));
